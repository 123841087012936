/*
 * File : BridgeConfigurationTable.tsx
 * Created : July 2024
 * Authors :
 * Synopsis:
 *
 * Copyright 2024 Audinate Pty Ltd and/or its licensors
 *
 */
import React from 'react';
import Table from '../../components/Table';
import { FormattedMessage } from 'react-intl';
import styles from './Configure.module.scss';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import classNames from 'classnames';
import Input from '../../components/Input';
import { RemoteConfiguration } from '../../graphql/graphqlGenerated';
import MaskedInput from '../../components/MaskedInput';

interface BridgeConfigurationTableProps {
  remoteConfiguration: RemoteConfiguration | null;
  onRemoteConfigurationChange: (
    bridgeUrl: string | null,
    bridgePassword: string | null
  ) => void;
}

const BridgeConfigurationTable = ({
  remoteConfiguration,
  onRemoteConfigurationChange,
}: BridgeConfigurationTableProps) => {
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Header>
            <FormattedMessage
              id="configureBridge.header"
              defaultMessage="Bridge"
            />
          </Table.Header>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.DataCell>
            <div>
              <span>{'Bridge URL'}</span>
            </div>
          </Table.DataCell>
          <Table.DataCell>
            <div>
              <span className={styles['configure-bridge__arrow-icon']}>
                <Arrow />
              </span>
            </div>
          </Table.DataCell>
          <Table.DataCell>
            <div>
              <span>
                <Input
                  id={`bridgeUrl-input`}
                  className={classNames(
                    styles['configure-bridge__url-input'],
                    styles['configure-bridge__input']
                  )}
                  defaultValue={remoteConfiguration?.dnsNameOrIpAddress ?? ''}
                  onBlur={(e) => {
                    onRemoteConfigurationChange(
                      e.currentTarget.value,
                      remoteConfiguration?.password ?? null
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onRemoteConfigurationChange(
                        e.currentTarget.value,
                        remoteConfiguration?.password ?? null
                      );
                    }
                  }}
                />
              </span>
            </div>
          </Table.DataCell>
        </Table.Row>
        <Table.Row>
          <Table.DataCell>
            <div>
              <span>{'Bridge password'}</span>
            </div>
          </Table.DataCell>
          <Table.DataCell>
            <div>
              <span className={styles['configure-bridge__arrow-icon']}>
                <Arrow />
              </span>
            </div>
          </Table.DataCell>
          <Table.DataCell>
            <div>
              <span>
                <MaskedInput
                  id={`bridgePassword-input`}
                  className={classNames(
                    styles['configure-bridge__url-input'],
                    styles['configure-bridge__input']
                  )}
                  defaultValue={remoteConfiguration?.password ?? ''}
                  onBlur={(e) => {
                    onRemoteConfigurationChange(
                      remoteConfiguration?.dnsNameOrIpAddress ?? null,
                      e.currentTarget.value
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onRemoteConfigurationChange(
                        remoteConfiguration?.dnsNameOrIpAddress ?? null,
                        e.currentTarget.value
                      );
                    }
                  }}
                />
              </span>
            </div>
          </Table.DataCell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default BridgeConfigurationTable;
