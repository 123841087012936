/*
 * File : endpointInfoService.ts
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import { useContext, useEffect } from 'react';
import { GraphqlError } from '../../graphql/GraphqlError';
import { AuthContext } from '../../context/authContext';
import {
  EndpointInfo,
  useGetEndpointInfoQuery,
} from '../../graphql/graphqlGenerated';
import { ErrorPolicy } from '@apollo/client/core/watchQueryOptions';

export const useFetchEndpointInfo = (
  pollInterval = 5000,
  errorPolicy: ErrorPolicy = 'all',
  fallbackDeviceName = '',
  fallbackDomainName = ''
) => {
  const context = useContext(AuthContext);

  const {
    data: endpointInfoData,
    loading: endpointInfoLoading,
    error: endpointInfoError,
  } = useGetEndpointInfoQuery({
    onError: () => {},
    pollInterval: pollInterval,
    errorPolicy: errorPolicy,
  });

  const isDataError =
    endpointInfoData?.endpointInfo.__typename.endsWith('Error');
  const endpointInfoErrorMessage = endpointInfoError?.message;

  useEffect(
    () => {
      if (isDataError) {
        context.setError(
          (endpointInfoData?.endpointInfo as GraphqlError).message
        );
      } else if (endpointInfoError) {
        context.setError(endpointInfoError.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [endpointInfoErrorMessage, isDataError]
  );

  function isEndpointInfoDataAvailable() {
    return (
      !endpointInfoLoading &&
      !endpointInfoData?.endpointInfo.__typename.endsWith('Error') &&
      !endpointInfoError
    );
  }

  function isLicenseDataAvailable() {
    return !endpointInfoLoading && !endpointInfoError;
  }

  function getDeviceNameOrElse(defaultValue: string) {
    return isEndpointInfoDataAvailable()
      ? (endpointInfoData?.endpointInfo as EndpointInfo).endpointDeviceName ??
          defaultValue
      : defaultValue;
  }

  function getDomainNameOrElse(defaultValue: string) {
    return isEndpointInfoDataAvailable()
      ? (endpointInfoData?.endpointInfo as EndpointInfo).endpointDanteDomain ??
          defaultValue
      : defaultValue;
  }

  function getLicenseActivated() {
    return isLicenseDataAvailable()
      ? endpointInfoData?.endpointInfo?.licenseActivated ?? false
      : undefined;
  }

  return {
    data: {
      deviceName: getDeviceNameOrElse(fallbackDeviceName),
      domainName: getDomainNameOrElse(fallbackDomainName),
      licenseActivated: getLicenseActivated(),
    },
    loading: endpointInfoLoading,
    error: endpointInfoError,
  };
};
