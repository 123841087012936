/*
 * File : Header.tsx
 * Created : May 2022
 * Authors :
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import styles from './Header.module.scss';
import { ReactComponent as ConnectMonitorLogo } from 'src/assets/icons/danteConnectLogo/header-logo-dante-monitor-white.svg';
import { ReactComponent as ConnectContributionLogo } from 'src/assets/icons/danteConnectLogo/header-logo-dante-contribution-white.svg';
import { ReactComponent as ConnectBridgeLogo } from 'src/assets/icons/danteConnectLogo/header-logo-dante-monitor-white.svg'; //todo replace with bridge logo
import { FormattedMessage } from 'react-intl/lib';
import { userLogoutAction } from 'src/helpers/functions';
import { useNavigate } from 'react-router-dom';
import { AppTypeEnum, isAppType } from 'src/ts/enums/appTypeEnum';
import Hamburger from '../Hamburger';

interface HeaderProps {
  domainName: string;
  deviceName: string;
  adminRoute?: boolean;
  cutoffContent?: boolean;
}

const Header = ({
  deviceName,
  domainName,
  adminRoute = true,
  cutoffContent = true,
}: HeaderProps) => {
  const navigate = useNavigate();
  const onLogout = () => {
    userLogoutAction();
    navigate('/');
  };
  const deviceDomainHeaderId =
    deviceName === ''
      ? ' '
      : deviceName + (domainName === '' ? '' : ' (' + domainName + ')');
  return (
    <>
      <div>
        <div className={styles.header}>
          {isAppType(AppTypeEnum.BRIDGE) ? (
            <ConnectBridgeLogo className={styles.header__logo} />
          ) : isAppType(AppTypeEnum.CONTRIBUTION) ? (
            <ConnectContributionLogo className={styles.header__logo} />
          ) : (
            <ConnectMonitorLogo className={styles.header__logo} />
          )}
          <div className={styles.header__text}>
            <div id={'deviceAndDomainInfo'}>
              <FormattedMessage
                id={'deviceDomainHeaderId'}
                defaultMessage={deviceDomainHeaderId}
              />
            </div>
            {adminRoute && (
              <>
                <button className={styles.header__logout} onClick={onLogout}>
                  Logout
                </button>

                <div className={styles['header__hamburger-container']}></div>
              </>
            )}
          </div>
        </div>
      </div>
      {adminRoute && <Hamburger />}
    </>
  );
};

export default Header;
