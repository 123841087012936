/*
 * File : ContextualHelp.tsx
 * Created : March 2022
 * Authors :
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import { PropsWithChildren } from 'react';
import styles from './ContextualHelp.module.scss';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning-border.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';

interface ContextualHelpProps {
  header: React.ReactNode;
  onClose: () => void;
}

const ContextualHelp = ({
  header,
  onClose,
  children,
}: PropsWithChildren<ContextualHelpProps>) => {
  return (
    <article className={styles['contextual-help']}>
      <WarningIcon className={styles['contextual-help__icon']} />
      <button
        className={styles['contextual-help__close-button']}
        onClick={() => onClose()}
      >
        <CloseIcon />
      </button>
      <div>
        <h2 className={styles['contextual-help__header']}>{header}</h2>
        <div className={styles['contextual-help__content']}>{children}</div>
      </div>
    </article>
  );
};

export default ContextualHelp;
