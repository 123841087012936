/*
 * File : Configure.tsx
 * Created : April 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Configure.module.scss';
import { ReactComponent as MockDeviceIcon } from 'src/assets/icons/mock-device.svg';
import ChannelsTable from './ChannelsTable';
import { AuthContext, ContextualMessageType } from '../../context/authContext';
import {
  MonitoringConfiguration,
  ReceiveChannelConfiguration,
  RemoteConfiguration,
  TransmitChannelConfiguration,
  useGetMonitoringConfigurationQuery,
  useMutationUpdateMonitoringConfigurationMutation,
} from '../../graphql/graphqlGenerated';
import { GraphqlError } from '../../graphql/GraphqlError';
import Loading from '../../components/Loading';
import { AppTypeEnum, isAppType } from '../../ts/enums/appTypeEnum';
import { ChannelKindEnum } from '../../ts/enums/channelKindEnum';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import BridgeConfigurationTable from './BridgeConfigurationTable';
import {
  hasActiveConfigurationBeenChanged,
  hasConfigurationBeenDeactivated,
} from '../../ts/helpers/bridge';

interface ConfigureProps {
  deviceName: string;
}

export const changedActiveConfigurationMessage: Readonly<ContextualMessageType> =
  {
    title: `Configuration is active`,
    message: `Modified started configuration. Please stop and start the configuration to update the connection state.`,
  };

const Configure = ({ deviceName }: ConfigureProps) => {
  const [selectedChannelKind, setSelectedChannelKind] =
    useState<ChannelKindEnum>(ChannelKindEnum.RX);
  const context = useContext(AuthContext);

  const [monitoringConfiguration, setMonitoringConfiguration] =
    useState<MonitoringConfiguration | null>(null);

  // used to update both rx and tx channel configuration for app type 'bridge'
  const [receiveChannelConfiguration, setReceiveChannelConfiguration] =
    useState<ReceiveChannelConfiguration[]>([]);

  // used to update both rx and tx channel name flag for app type 'bridge'
  const [useRxDanteChannelNames, setUseRxDanteChannelNames] =
    useState<boolean>(false);

  const [transmitChannelConfiguration, setTransmitChannelConfiguration] =
    useState<TransmitChannelConfiguration[]>([]);

  const [useTxDanteChannelNames, setUseTxDanteChannelNames] =
    useState<boolean>(false);

  const [remoteConfiguration, setRemoteConfiguration] =
    useState<RemoteConfiguration | null>(null);

  const { loading: monitoringConfigurationLoading } =
    useGetMonitoringConfigurationQuery({
      onError: (error) => {
        context.setError(error.message);
      },
      onCompleted: (data) => {
        if (data?.monitoringConfiguration.__typename?.endsWith('Error')) {
          context.setError(
            (data.monitoringConfiguration as GraphqlError).message
          );
        } else {
          const monitoringConfigurationData =
            data.monitoringConfiguration as MonitoringConfiguration;
          if (
            isAppType(AppTypeEnum.BRIDGE) &&
            hasConfigurationBeenDeactivated(
              monitoringConfigurationData,
              monitoringConfiguration
            ) &&
            context.contextualMessage?.message ===
              changedActiveConfigurationMessage.message
          ) {
            context.emptyContextualMessage();
          }
          setMonitoringConfiguration(monitoringConfigurationData);
        }
      },
      pollInterval: 5000,
      errorPolicy: 'all',
    });

  const [updateMonitoringConfiguration] =
    useMutationUpdateMonitoringConfigurationMutation({
      onError: (error) => {
        context.setError(error.message);
      },
      onCompleted: (data) => {
        if (data.updateMonitoringConfiguration.__typename?.endsWith('Error')) {
          context.setError(
            (data.updateMonitoringConfiguration as GraphqlError).message
          );
        } else {
          const monitoringConfigurationData =
            data.updateMonitoringConfiguration as MonitoringConfiguration;
          if (
            isAppType(AppTypeEnum.BRIDGE) &&
            hasActiveConfigurationBeenChanged(
              monitoringConfigurationData,
              monitoringConfiguration
            )
          ) {
            context.setContextualMessage({
              message: changedActiveConfigurationMessage.message,
              title: changedActiveConfigurationMessage.title,
            });
          }
          setMonitoringConfiguration(monitoringConfigurationData);
        }
      },
    });

  const onUseDanteChannelNamesChange = async (
    kind: ChannelKindEnum,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const useRxDanteChannelNames =
      kind === ChannelKindEnum.RX ? e.target.checked : null;
    const useTxDanteChannelNames = isAppType(AppTypeEnum.BRIDGE)
      ? useRxDanteChannelNames
      : kind === ChannelKindEnum.TX
      ? e.target.checked
      : null;
    await updateMonitoringConfiguration({
      variables: {
        updateMonitoringConfigurationInput: {
          ...(useRxDanteChannelNames != null
            ? { useRxDanteChannelNames: useRxDanteChannelNames }
            : {}),
          ...(useTxDanteChannelNames != null
            ? { useTxDanteChannelNames: useTxDanteChannelNames }
            : {}),
        },
      },
    });
  };

  const onChannelSelectionChange = async (
    danteChannelName: string,
    kind: ChannelKindEnum,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rxChannels =
      kind === ChannelKindEnum.RX
        ? receiveChannelConfiguration
            .map((channel) =>
              channel.danteName === danteChannelName
                ? { ...channel, selected: e.target.checked }
                : channel
            )
            .map(toChannelMonitoringConfigurationModelInput)
        : null;
    const txChannels = isAppType(AppTypeEnum.BRIDGE)
      ? rxChannels
      : kind === ChannelKindEnum.TX
      ? transmitChannelConfiguration
          .map((channel) =>
            channel.danteName === danteChannelName
              ? { ...channel, selected: e.target.checked }
              : channel
          )
          .map(toChannelMonitoringConfigurationModelInput)
      : null;
    await updateMonitoringConfiguration({
      variables: {
        updateMonitoringConfigurationInput: {
          useRxDanteChannelNames: useRxDanteChannelNames,
          rxChannels: rxChannels,
          useTxDanteChannelNames: useTxDanteChannelNames,
          txChannels: txChannels,
        },
      },
    });
  };

  const onChannelNameChange = async (
    danteChannelName: string,
    newUserName: string,
    kind: ChannelKindEnum
  ) => {
    const rxChannels =
      receiveChannelConfiguration && kind === ChannelKindEnum.RX
        ? receiveChannelConfiguration
            .map((channel) =>
              channel.danteName === danteChannelName
                ? { ...channel, userName: newUserName }
                : channel
            )
            .map(toChannelMonitoringConfigurationModelInput)
        : null;
    const txChannels = isAppType(AppTypeEnum.BRIDGE)
      ? rxChannels
      : transmitChannelConfiguration && kind === ChannelKindEnum.TX
      ? transmitChannelConfiguration
          .map((channel) =>
            channel.danteName === danteChannelName
              ? { ...channel, userName: newUserName }
              : channel
          )
          .map(toChannelMonitoringConfigurationModelInput)
      : null;
    await updateMonitoringConfiguration({
      variables: {
        updateMonitoringConfigurationInput: {
          useRxDanteChannelNames: useRxDanteChannelNames,
          rxChannels: rxChannels,
          txChannels: txChannels,
          useTxDanteChannelNames: useTxDanteChannelNames,
        },
      },
    });
  };

  const onRemoteConfigurationChange = async (
    bridgeUrl: string | null,
    bridgePassword: string | null
  ) => {
    await updateMonitoringConfiguration({
      variables: {
        updateMonitoringConfigurationInput: {
          remoteConfiguration: {
            dnsNameOrIpAddress: bridgeUrl,
            password: bridgePassword,
          },
        },
      },
    });
  };

  const toChannelMonitoringConfigurationModelInput = (
    channel: ReceiveChannelConfiguration | TransmitChannelConfiguration
  ) => ({
    danteChannelName: channel.danteName,
    selected: channel.selected,
    userChannelName: channel.userName,
  });

  useEffect(() => {
    setReceiveChannelConfiguration(
      monitoringConfiguration?.rxChannels
        ? monitoringConfiguration.rxChannels
        : []
    );
    setUseRxDanteChannelNames(
      monitoringConfiguration?.useRxDanteChannelNames
        ? monitoringConfiguration.useRxDanteChannelNames
        : false
    );
    setTransmitChannelConfiguration(
      monitoringConfiguration?.txChannels
        ? monitoringConfiguration.txChannels
        : []
    );
    setUseTxDanteChannelNames(
      monitoringConfiguration?.useTxDanteChannelNames
        ? monitoringConfiguration.useTxDanteChannelNames
        : false
    );
    setRemoteConfiguration(
      monitoringConfiguration?.remoteConfiguration
        ? monitoringConfiguration.remoteConfiguration
        : null
    );
  }, [monitoringConfiguration]);

  const loadingState = useMemo(
    () => monitoringConfigurationLoading,
    [monitoringConfigurationLoading]
  );

  if (loadingState) {
    return <Loading />;
  }

  function bridgeConfiguration(): JSX.Element {
    return (
      <BridgeConfigurationTable
        remoteConfiguration={remoteConfiguration}
        onRemoteConfigurationChange={onRemoteConfigurationChange}
      />
    );
  }

  function rxChannelsConfiguration(): JSX.Element {
    return (
      <ChannelsTable
        channelKind={ChannelKindEnum.RX}
        channelConfiguration={receiveChannelConfiguration}
        useDanteChannelNames={useRxDanteChannelNames}
        onUseDanteChannelNamesChange={onUseDanteChannelNamesChange}
        onChannelNameChange={onChannelNameChange}
        onChannelSelectionChange={onChannelSelectionChange}
      />
    );
  }

  function txChannelsConfiguration(): JSX.Element {
    return (
      <ChannelsTable
        channelKind={ChannelKindEnum.TX}
        channelConfiguration={transmitChannelConfiguration}
        useDanteChannelNames={useTxDanteChannelNames}
        onUseDanteChannelNamesChange={onUseDanteChannelNamesChange}
        onChannelNameChange={onChannelNameChange}
        onChannelSelectionChange={onChannelSelectionChange}
      />
    );
  }

  return (
    <main className={styles['configure-channels']}>
      <h1 className={styles['configure-channels__header']}>
        <FormattedMessage
          id="ConfigureChannels.configureChannelsHeading"
          defaultMessage="Configure channels"
        />
      </h1>
      <div
        id="device-details-header-wrapper"
        className={styles['configure-channels__device-details-header-wrapper']}
      >
        {deviceName && (
          <div>
            <h2 className={styles['configure-channels__device-details-header']}>
              <span className={styles['configure-channels__device-icon']}>
                <MockDeviceIcon />
              </span>
              <div>
                <div
                  id="device-name"
                  className={styles['configure-channels__device-name']}
                >
                  {deviceName}
                </div>
                <div
                  className={styles['configure-channels__device-description']}
                >
                  <FormattedMessage
                    id="configureChannels.deviceDescription"
                    defaultMessage={`${
                      isAppType(AppTypeEnum.BRIDGE)
                        ? 'Bridge'
                        : isAppType(AppTypeEnum.MONITORING)
                        ? 'Remote Monitor'
                        : 'Remote Contributor'
                    }`}
                  />
                </div>
              </div>
            </h2>
          </div>
        )}
      </div>
      {isAppType(AppTypeEnum.BRIDGE) && (
        <div style={{ marginBottom: '50px' }}>{bridgeConfiguration()}</div>
      )}
      {((isAppType(AppTypeEnum.BRIDGE) || isAppType(AppTypeEnum.MONITORING)) &&
        rxChannelsConfiguration()) ||
        (isAppType(AppTypeEnum.CONTRIBUTION) && (
          <div className={styles['tabs__change-page']}>
            <ul>
              <li>
                <Link
                  id={'btn-receive'}
                  className={classNames(
                    ChannelKindEnum.TX === selectedChannelKind &&
                      styles['tabs__change-page-button--active']
                  )}
                  onClick={() => setSelectedChannelKind(ChannelKindEnum.TX)}
                  to={`/configure`}
                >
                  <FormattedMessage
                    id="configuration.txChannelTab"
                    defaultMessage="Contribution Channels"
                  />
                </Link>
              </li>
              <li>
                <Link
                  id={'btn-send'}
                  className={classNames(
                    ChannelKindEnum.RX === selectedChannelKind &&
                      styles['tabs__change-page-button--active']
                  )}
                  onClick={() => setSelectedChannelKind(ChannelKindEnum.RX)}
                  to={`/configure`}
                >
                  <FormattedMessage
                    id="configuration.rxChannelTab"
                    defaultMessage="Monitoring Channels"
                  />
                </Link>
              </li>
            </ul>
            {selectedChannelKind === ChannelKindEnum.RX &&
              rxChannelsConfiguration()}
            {selectedChannelKind === ChannelKindEnum.TX &&
              txChannelsConfiguration()}
          </div>
        ))}
    </main>
  );
};

export default Configure;
