/*
 * File : mobileMenuContext.tsx
 * Created : Oct 2022
 * Authors : Steve Li
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import React, { useReducer, createContext } from 'react';

type MobileMenuContextStateType = {
  firstLevelMenuOpen: boolean;
  pageSlideAnimation: 'right' | 'left' | null;
};
type MobileMenuContextType = {
  firstLevelMenuOpen: boolean;
  pageSlideAnimation: 'right' | 'left' | null;
  setPageSlideAnimation: (newValue: 'right' | 'left' | null) => void;
  setFirstLevelMenuOpen: (newValue: boolean) => void;
};
const initialState: MobileMenuContextStateType = {
  firstLevelMenuOpen: false,
  pageSlideAnimation: null,
};
const MobileMenuContext = createContext<MobileMenuContextType>({
  firstLevelMenuOpen: false,
  pageSlideAnimation: null,
  setFirstLevelMenuOpen: (newValue) => {},
  setPageSlideAnimation: (newValue) => {},
});

function mobileMenuReducer(
  state: MobileMenuContextStateType,
  action: MobileMenuContextAction
): MobileMenuContextStateType {
  switch (action.type) {
    case 'SET_FIRST_MENU_OPEN':
      return {
        ...state,
        firstLevelMenuOpen: action.payload,
      };
    case 'SET_PAGE_SLIDE_ANIMATION':
      return {
        ...state,
        pageSlideAnimation: action.payload,
      };
    default:
      return state;
  }
}

type MobileMenuContextAction =
  | setFirstLevelMenuOpenActionType
  | setPageSlideAnimationActionType;

type setFirstLevelMenuOpenActionType = {
  type: 'SET_FIRST_MENU_OPEN';
  payload: boolean;
};

type setPageSlideAnimationActionType = {
  type: 'SET_PAGE_SLIDE_ANIMATION';
  payload: 'right' | 'left' | null;
};

function MobileMenuContextProvider(props: React.PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer<
    (
      state: MobileMenuContextStateType,
      action: MobileMenuContextAction
    ) => MobileMenuContextStateType
  >(mobileMenuReducer, initialState);
  const setFirstLevelMenuOpen = (newValue: boolean) => {
    if (newValue === true && document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    dispatch({ type: 'SET_FIRST_MENU_OPEN', payload: newValue });
  };
  const setPageSlideAnimation = async (newValue: 'right' | 'left' | null) => {
    if (newValue === 'left' || newValue === 'right') {
      dispatch({ type: 'SET_PAGE_SLIDE_ANIMATION', payload: newValue });
      setTimeout(function () {
        dispatch({ type: 'SET_PAGE_SLIDE_ANIMATION', payload: null });
      }, 600);
    }
  };
  return (
    <MobileMenuContext.Provider
      value={{
        firstLevelMenuOpen: state.firstLevelMenuOpen,
        pageSlideAnimation: state.pageSlideAnimation,
        setFirstLevelMenuOpen,
        setPageSlideAnimation,
      }}
      {...props}
    />
  );
}

export { MobileMenuContext, MobileMenuContextProvider };
