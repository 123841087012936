/*
 * File : Deploy.tsx
 * Created : April 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import React, { useContext, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import { AuthContext, ContextualMessageType } from '../../context/authContext';
import Loading from '../../components/Loading';
import styles from './Deploy.module.scss';
import { FormattedMessage } from 'react-intl';
import {
  MonitoringConfiguration,
  RemoteClient,
  SignalStatus,
  useGetMonitoringConfigurationQuery,
  useGetRemoteClientsQuery,
  useMutationActivateMonitoringConfigurationMutation,
  useMutationDeactivateMonitoringConfigurationMutation,
} from '../../graphql/graphqlGenerated';
import Table from '../../components/Table';
import Button from '../../components/Button';
import copyPasteIcon from 'src/assets/icons/copy-paste-icon.svg';
import { GraphqlError } from '../../graphql/GraphqlError';
import green from 'src/assets/icons/Speaker_Icon_green.svg';
import red from 'src/assets/icons/Speaker_Icon_red.svg';
import gray from 'src/assets/icons/Speaker_Icon_gray.svg';
import { AppTypeEnum, isAppType } from '../../ts/enums/appTypeEnum';
import {
  hasConfigurationBeenDeactivated,
  isActivatedConfigurationStale,
} from '../../ts/helpers/bridge';
import { changedActiveConfigurationMessage } from '../Configure';

const activeConfigurationHasChangedMessage: Readonly<ContextualMessageType> = {
  title: `Configuration has changed`,
  message: `Configuration was modified. Please stop and start the configuration to update the connection state.`,
};

const Deploy = () => {
  const context = useContext(AuthContext);
  const [monitoringConfiguration, setMonitoringConfiguration] =
    useState<MonitoringConfiguration | null>(null);
  const [remoteClients, setRemoteClients] = useState<RemoteClient[]>([]);

  const configurationId: string | null = monitoringConfiguration?.id ?? null;

  const { loading: remoteClientsLoading } = useGetRemoteClientsQuery({
    onError: (error) => {
      context.setError(error.message);
      setRemoteClients([]);
    },
    onCompleted: (data) => {
      setRemoteClients(data.remoteClients);
    },
    pollInterval: 1000,
    errorPolicy: 'all',
  });

  const { loading: monitoringConfigurationLoading } =
    useGetMonitoringConfigurationQuery({
      onError: (error) => {
        context.setError(error.message);
      },
      onCompleted: (data) => {
        if (data.monitoringConfiguration.__typename.endsWith('Error')) {
          context.setError(
            (data.monitoringConfiguration as GraphqlError).message
          );
          setMonitoringConfiguration(null);
        } else {
          const monitoringConfigurationData =
            data.monitoringConfiguration as MonitoringConfiguration;
          if (isAppType(AppTypeEnum.BRIDGE)) {
            if (
              isActivatedConfigurationStale(
                monitoringConfigurationData,
                monitoringConfiguration
              )
            ) {
              context.setContextualMessage({
                message: activeConfigurationHasChangedMessage.message,
                title: activeConfigurationHasChangedMessage.title,
              });
            } else if (
              hasConfigurationBeenDeactivated(
                monitoringConfigurationData,
                monitoringConfiguration
              ) &&
              (context.contextualMessage?.message ===
                activeConfigurationHasChangedMessage.message ||
                context.contextualMessage?.message ===
                  changedActiveConfigurationMessage.message)
            ) {
              context.emptyContextualMessage();
            }
          }
          setMonitoringConfiguration(monitoringConfigurationData);
        }
      },
      pollInterval: 1000,
      errorPolicy: 'all',
    });

  const [activateConfiguration] =
    useMutationActivateMonitoringConfigurationMutation({
      onError: (error) => {
        context.setError(error.message);
      },
      onCompleted: (data) => {
        if (data.activateMonitoringConfiguration.__typename.endsWith('Error')) {
          context.setError(
            (data.activateMonitoringConfiguration as GraphqlError).message
          );
          setMonitoringConfiguration(null);
        } else {
          setMonitoringConfiguration(
            data.activateMonitoringConfiguration as MonitoringConfiguration
          );
        }
      },
    });

  const [deactivateConfiguration] =
    useMutationDeactivateMonitoringConfigurationMutation({
      variables: {
        deactivateMonitoringConfigurationId: configurationId ?? '',
      },
      onError: (error) => {
        context.setError(error.message);
      },
      onCompleted: (data) => {
        if (
          data.deactivateMonitoringConfiguration.__typename.endsWith('Error')
        ) {
          context.setError(
            (data.deactivateMonitoringConfiguration as GraphqlError).message
          );
          setMonitoringConfiguration(null);
        } else {
          const monitoringConfigurationData =
            data.deactivateMonitoringConfiguration as MonitoringConfiguration;
          if (
            isAppType(AppTypeEnum.BRIDGE) &&
            (context.contextualMessage?.message ===
              activeConfigurationHasChangedMessage.message ||
              context.contextualMessage?.message ===
                changedActiveConfigurationMessage.message)
          ) {
            context.emptyContextualMessage();
          }
          setMonitoringConfiguration(monitoringConfigurationData);
        }
      },
    });

  const loadingState = useMemo(
    () => remoteClientsLoading || monitoringConfigurationLoading,
    [remoteClientsLoading, monitoringConfigurationLoading]
  );

  if (loadingState) {
    return <Loading />;
  }

  function displayStartButton() {
    return configurationId === null;
  }

  function resolveClientUrl(): string {
    return `${window.location.host}/monitoring/${configurationId}`;
  }

  function resolveSignalPresenceStatusIcon(
    signalStatus: SignalStatus
  ): JSX.Element | undefined {
    let signalStatusEnum =
      Object.entries(SignalStatus).find(
        ([key, val]) => key === signalStatus || val === signalStatus
      )?.[1] ?? null;
    switch (signalStatusEnum) {
      case SignalStatus.Audio:
        return <img src={green} alt="" width={32} height={32} />;
      case SignalStatus.Clipping:
        return <img src={red} alt="" width={32} height={32} />;
      case SignalStatus.NoAudio:
        return <img src={gray} alt="" width={32} height={32} />;
      default:
        return;
    }
  }

  return (
    <main className={styles['deploy-sites']}>
      <div className={styles['deploy-sites__title-container']}>
        <div className={styles['deploy-sites__title']}>Deploy</div>

        <div className={styles['deploy-sites__status_info_text']}>
          <p id={'deployStatus'}>
            {displayStartButton() ? 'Status: Stopped' : 'Status: Started'}
          </p>
          <p style={{ paddingLeft: '15px' }} />
          <Button
            id={'startStopButton'}
            variant={'primary'}
            onClick={
              displayStartButton()
                ? () => activateConfiguration()
                : () => deactivateConfiguration()
            }
          >
            <FormattedMessage
              id={
                displayStartButton()
                  ? 'deploy.sites.button.start'
                  : 'deploy.sites.button.stop'
              }
              defaultMessage={displayStartButton() ? 'Start' : 'Stop'}
            />
          </Button>
        </div>
      </div>

      <br />
      {configurationId && !isAppType(AppTypeEnum.BRIDGE) && (
        <div className={styles['deploy-sites__url']}>
          <div style={{ fontWeight: 'bold' }}>
            <FormattedMessage
              id="deploy.clientUrl"
              defaultMessage={'Client URL:'}
            />
          </div>
          <p style={{ paddingLeft: '15px' }} />
          <div className={styles['deploy-sites__qr_code_container']}>
            <div className={styles['deploy-sites__qr_code']}>
              <QRCode
                value={`https://${resolveClientUrl()}`}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
          <div className={styles['deploy-sites__url_text']}>
            <a
              id={'clientUrl'}
              style={{ textDecoration: 'underline' }}
              href={`/monitoring/${configurationId}`}
            >
              {resolveClientUrl()}
            </a>
            <p style={{ paddingLeft: '15px' }} />
            <button
              onClick={() => {
                navigator.clipboard
                  .writeText(resolveClientUrl())
                  .then(() => {});
                context.setSuccess('URL copied to clipboard.');
              }}
            >
              <img src={copyPasteIcon} alt="Home" width={32} height={32} />
            </button>
          </div>
        </div>
      )}

      {isAppType(AppTypeEnum.BRIDGE) ? (
        remoteClients.length === 0 ? (
          <Table
            className={styles['deploy-sites__table']}
            header={
              <FormattedMessage
                id="deploy.restrictedHeading"
                defaultMessage="Metrics"
              />
            }
          >
            <Table.Head>
              <Table.Row>
                <Table.Header>
                  <FormattedMessage
                    id="deploy.metric"
                    defaultMessage="Metric"
                  />
                </Table.Header>
                <Table.Header>
                  <FormattedMessage id="deploy.value" defaultMessage="Value" />
                </Table.Header>
                <Table.Header />
              </Table.Row>
            </Table.Head>
            <Table.Body />
          </Table>
        ) : (
          remoteClients.map((remoteClient, index) => (
            <Table
              className={styles['deploy-sites__table']}
              header={
                <FormattedMessage
                  id={`deploy.restrictedHeading-${index}`}
                  defaultMessage={`Metrics for ${
                    remoteClient.ipAddress ?? 'n/a'
                  } (${remoteClient.status ?? 'n/a'})`}
                />
              }
            >
              <Table.Head>
                <Table.Row>
                  <Table.Header>
                    <FormattedMessage
                      id={`deploy.metric-${index}`}
                      defaultMessage="Metric"
                    />
                  </Table.Header>
                  <Table.Header>
                    <FormattedMessage
                      id={`deploy.value-${index}`}
                      defaultMessage="Value"
                    />
                  </Table.Header>
                  <Table.Header />
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <Table.Row
                  key={`${
                    remoteClient.ipAddress ?? index
                  }-receiveNumberOfChannels`}
                >
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveNumberOfChannels-${index}`}
                  >
                    <div>
                      <span>Number of channels:</span>
                    </div>
                  </Table.DataCell>
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveNumberOfChannelsValue-${index}`}
                  >
                    <div>
                      <span>
                        {remoteClient.metrics?.receiveNumberOfChannels}
                      </span>
                    </div>
                  </Table.DataCell>
                </Table.Row>
                <Table.Row
                  key={`${
                    remoteClient.ipAddress ?? index
                  }-receiveBufferOverruns`}
                >
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveBufferOverruns-${index}`}
                  >
                    <div>
                      <span>Buffer overruns:</span>
                    </div>
                  </Table.DataCell>
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveBufferOverrunsValue-${index}`}
                  >
                    <div>
                      <span>{remoteClient.metrics?.receiveBufferOverruns}</span>
                    </div>
                  </Table.DataCell>
                </Table.Row>
                <Table.Row
                  key={`${
                    remoteClient.ipAddress ?? index
                  }-receiveBufferUnderruns`}
                >
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveBufferUnderruns-${index}`}
                  >
                    <div>
                      <span>Buffer underruns:</span>
                    </div>
                  </Table.DataCell>
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveBufferUnderrunsValue-${index}`}
                  >
                    <div>
                      <span>
                        {remoteClient.metrics?.receiveBufferUnderruns}
                      </span>
                    </div>
                  </Table.DataCell>
                </Table.Row>
                <Table.Row
                  key={`${
                    remoteClient.ipAddress ?? index
                  }-receiveBufferTargetInMs`}
                >
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveBufferTargetInMs-${index}`}
                  >
                    <div>
                      <span>Buffer target (in ms):</span>
                    </div>
                  </Table.DataCell>
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveBufferTargetInMs-${index}`}
                  >
                    <div>
                      <span>
                        {remoteClient.metrics?.receiveBufferTargetInMs}
                      </span>
                    </div>
                  </Table.DataCell>
                </Table.Row>
                <Table.Row
                  key={`${remoteClient.ipAddress ?? index}-receiveJitterInMs`}
                >
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveJitterInMs-${index}`}
                  >
                    <div>
                      <span>Jitter:</span>
                    </div>
                  </Table.DataCell>
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveJitterInMs-${index}`}
                  >
                    <div>
                      <span>
                        {remoteClient.metrics?.receiveJitterInMs?.toFixed(4)}
                      </span>
                    </div>
                  </Table.DataCell>
                </Table.Row>
                <Table.Row
                  key={`${
                    remoteClient.ipAddress ?? index
                  }-receiveRemovedSamplesForAcceleration`}
                >
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveRemovedSamplesForAcceleration-${index}`}
                  >
                    <div>
                      <span>Removed samples for acceleration:</span>
                    </div>
                  </Table.DataCell>
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveRemovedSamplesForAccelerationValue-${index}`}
                  >
                    <div>
                      <span>
                        {
                          remoteClient.metrics
                            ?.receiveRemovedSamplesForAcceleration
                        }
                      </span>
                    </div>
                  </Table.DataCell>
                </Table.Row>
                <Table.Row
                  key={`${
                    remoteClient.ipAddress ?? index
                  }-receiveInsertedSamplesForDeceleration`}
                >
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveInsertedSamplesForDeceleration-${index}`}
                  >
                    <div>
                      <span>Inserted samples for deceleration:</span>
                    </div>
                  </Table.DataCell>
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveInsertedSamplesForDeceleration-${index}`}
                  >
                    <div>
                      <span>
                        {
                          remoteClient.metrics
                            ?.receiveInsertedSamplesForDeceleration
                        }
                      </span>
                    </div>
                  </Table.DataCell>
                </Table.Row>
                <Table.Row
                  key={`${remoteClient.ipAddress ?? index}-receiveHighestRms`}
                >
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveHighestRms-${index}`}
                  >
                    <div>
                      <span>Audio level in RMS:</span>
                    </div>
                  </Table.DataCell>
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveHighestRms-${index}`}
                  >
                    <div>
                      <span>
                        {remoteClient.metrics?.receiveHighestRms?.toFixed(4)}
                      </span>
                    </div>
                  </Table.DataCell>
                </Table.Row>
                <Table.Row
                  key={`${
                    remoteClient.ipAddress ?? index
                  }-receiveLastSequenceNumber`}
                >
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveLastSequenceNumber-${index}`}
                  >
                    <div>
                      <span>Last sequence number:</span>
                    </div>
                  </Table.DataCell>
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveLastSequenceNumberValue-${index}`}
                  >
                    <div>
                      <span>
                        {remoteClient.metrics?.receiveLastSequenceNumber}
                      </span>
                    </div>
                  </Table.DataCell>
                </Table.Row>
                <Table.Row
                  key={`${
                    remoteClient.ipAddress ?? index
                  }-receiveMissingPackets`}
                >
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveMissingPackets-${index}`}
                  >
                    <div>
                      <span>Missing packets:</span>
                    </div>
                  </Table.DataCell>
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveMissingPackets-${index}`}
                  >
                    <div>
                      <span>{remoteClient.metrics?.receiveMissingPackets}</span>
                    </div>
                  </Table.DataCell>
                </Table.Row>
                <Table.Row
                  key={`${
                    remoteClient.ipAddress ?? index
                  }-receiveOutOfOrderPackets`}
                >
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveOutOfOrderPackets-${index}`}
                  >
                    <div>
                      <span>Out of order packets:</span>
                    </div>
                  </Table.DataCell>
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`receiveOutOfOrderPackets-${index}`}
                  >
                    <div>
                      <span>
                        {remoteClient.metrics?.receiveOutOfOrderPackets}
                      </span>
                    </div>
                  </Table.DataCell>
                </Table.Row>
              </Table.Body>
            </Table>
          ))
        )
      ) : (
        <Table
          className={styles['deploy-sites__table']}
          header={
            <FormattedMessage
              id="deploy.restrictedHeading"
              defaultMessage="Remote clients"
            />
          }
        >
          <Table.Head>
            <Table.Row>
              <Table.Header>
                <FormattedMessage
                  id="deploy.ipAddress"
                  defaultMessage="IP Address"
                />
              </Table.Header>
              <Table.Header>
                <FormattedMessage id="deploy.status" defaultMessage="Status" />
              </Table.Header>
              {isAppType(AppTypeEnum.CONTRIBUTION) && (
                <Table.Header>
                  <FormattedMessage
                    id="deploy.signalStatus"
                    defaultMessage="Signal"
                  />
                </Table.Header>
              )}
              <Table.Header />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {remoteClients.map((remoteClient, index) => (
              <Table.Row key={remoteClient.ipAddress}>
                <Table.DataCell
                  className={styles['deploy-sites__cell-wide']}
                  id={`ipAddress-${index}`}
                >
                  <div>
                    <span>{remoteClient.ipAddress}</span>
                  </div>
                </Table.DataCell>
                <Table.DataCell
                  className={styles['deploy-sites__cell-wide']}
                  id={`status-${index}`}
                >
                  <div>
                    <span>{remoteClient.status}</span>
                  </div>
                </Table.DataCell>
                {isAppType(AppTypeEnum.CONTRIBUTION) && (
                  <Table.DataCell
                    className={styles['deploy-sites__cell-wide']}
                    id={`signalStatus-${index}`}
                  >
                    {remoteClient.signalStatus &&
                      resolveSignalPresenceStatusIcon(
                        remoteClient.signalStatus
                      )}
                  </Table.DataCell>
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </main>
  );
};

export default Deploy;
