/*
 * File : MainContent.tsx
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import React, { useContext } from 'react';
import Header from '../../components/Header';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';
import SidebarItem from '../../components/Sidebar/SidebarItem';
import { FormattedMessage } from 'react-intl';
import Sidebar from '../../components/Sidebar';
import settingsIcon from 'src/assets/icons/page/Settings.svg';
import broadcastIcon from 'src/assets/icons/site/Broadcast.svg';
import { MobileMenuContext } from 'src/context/mobileMenuContext';
import mobileStyles from './MainContent.module.scss';

interface MainContentProps {
  deviceName: string;
  domainName: string;
}

const MainContent = ({ deviceName, domainName }: MainContentProps) => {
  const mobileContext = useContext(MobileMenuContext);
  return (
    <div className={mobileStyles.main}>
      <Header deviceName={deviceName} domainName={domainName} />
      <Sidebar
        className={classNames(
          mobileStyles['first-sidebar'],
          mobileContext.firstLevelMenuOpen &&
            mobileStyles['first-sidebar__active']
        )}
        secondary={true}
        expanded={true}
        topItems={[
          <SidebarItem
            label={
              <FormattedMessage
                id="sidebar.configureLabel"
                defaultMessage="Configure"
              />
            }
            href="/configure"
            key="/configure"
            onClick={() => {
              if (mobileContext.firstLevelMenuOpen === true) {
                mobileContext.setFirstLevelMenuOpen(false);
              }
            }}
            icon={<img src={settingsIcon} alt="" width={32} height={32} />}
          />,
          <SidebarItem
            label={
              <FormattedMessage
                id="sidebar.deployLabel"
                defaultMessage="Deploy"
              />
            }
            href="/deploy"
            key="/deploy"
            onClick={() => {
              if (mobileContext.firstLevelMenuOpen === true) {
                mobileContext.setFirstLevelMenuOpen(false);
              }
            }}
            icon={<img src={broadcastIcon} alt="" width={32} height={32} />}
          />,
        ]}
      />
      <Outlet />
    </div>
  );
};

export default MainContent;
