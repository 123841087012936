/*
 * File : Sidebar.tsx
 * Created : March 2022
 * Authors :
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import styles from './Sidebar.module.scss';
import classNames from 'classnames';

interface SidebarProps {
  topItems: React.ReactNode;
  middleItems?: React.ReactNode;
  bottomItems?: React.ReactNode;
  expanded?: boolean;
  secondary?: boolean;
  fixedMenu?: boolean;
  className?: string;
}

const Sidebar = ({
  topItems,
  middleItems,
  bottomItems,
  className: externalClassName,
  expanded = false,
  secondary = false,
  fixedMenu = false,
}: SidebarProps) => {
  if (fixedMenu) {
    return (
      <div
        className={classNames(
          styles.sidebar,
          expanded && styles['sidebar--expanded'],
          secondary && styles['sidebar--secondary'],
          fixedMenu && styles['sidebar--fixed'],
          externalClassName
        )}
      >
        <div className={styles['sidebar--fixed-top']}>{topItems}</div>
        <div className={styles['sidebar--fixed-middle']}>{middleItems}</div>
        <div className={styles['sidebar--fixed-bottom']}>{bottomItems}</div>
      </div>
    );
  }
  return (
    <div
      className={classNames(
        styles.sidebar,
        expanded && styles['sidebar--expanded'],
        secondary && styles['sidebar--secondary'],
        externalClassName
      )}
    >
      <div>{topItems}</div>
      <div>{bottomItems}</div>
    </div>
  );
};

export default Sidebar;
