/*
 * File : BridgeAppRoutes.tsx
 * Created : July 2024
 * Authors :
 * Synopsis:
 *
 * Copyright 2024 Audinate Pty Ltd and/or its licensors
 *
 */
import React, { useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainContent from '../pages/MainContent/MainContent';
import Deploy from '../pages/Deploy/Deploy';
import Configure from '../pages/Configure/Configure';
import Loading from '../components/Loading';
import Page404 from '../pages/Page404';
import { useFetchEndpointInfo } from '../ts/services/endpointInfoService';
import Unlicensed from 'src/pages/Unlicensed';

const BridgeAppRoutes = () => {
  const { data: endpointInfoData, loading: endpointInfoLoading } =
    useFetchEndpointInfo();

  const loadingState = useMemo(
    () => endpointInfoLoading,
    [endpointInfoLoading]
  );

  return (
    <div>
      {endpointInfoData.licenseActivated === false ? (
        <Unlicensed
          deviceName={endpointInfoData.deviceName}
          domainName={endpointInfoData.domainName}
        />
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              !loadingState && (
                <MainContent
                  deviceName={endpointInfoData.deviceName}
                  domainName={endpointInfoData.domainName}
                />
              )
            }
          >
            <Route path="/deploy" element={!loadingState && <Deploy />} />
            <Route
              path="/configure"
              element={
                !loadingState && (
                  <Configure deviceName={endpointInfoData.deviceName} />
                )
              }
            />
          </Route>
          <Route path="/login" element={<Navigate to="/configure" />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      )}
      {loadingState && <Loading fixedPosition />}
    </div>
  );
};

export default BridgeAppRoutes;
