import { getUserLoginInfo } from 'src/helpers/functions';
import { create } from 'zustand';

type LoginState = {
  isLoggedIn: string | undefined;
  setIsLoggedIn: () => void;
};

export const useLoginStore = create<LoginState>((set) => ({
  isLoggedIn: getUserLoginInfo(),
  setIsLoggedIn: () => set({ isLoggedIn: getUserLoginInfo() }),
}));
