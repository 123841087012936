/*
 * File : ModalMessageWrapper.tsx
 * Created : Sep 2022
 * Authors : Steve Li
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import { useContext } from 'react';
import { AuthContext } from 'src/context/authContext';
import Modal from '../Modal/Modal';

const ModalMessageWrapper = ({ children }: React.PropsWithChildren<{}>) => {
  const context = useContext(AuthContext);
  return (
    <>
      {children}
      {context.modalMessage && (
        <Modal
          isOpen={!!context.modalMessage}
          onIsOpenChange={() => context.emptyModalMessage()}
        >
          {context.modalMessage.message}
        </Modal>
      )}
    </>
  );
};

export default ModalMessageWrapper;
