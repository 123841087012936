/*
 * File : Checkbox.tsx
 * Created : April 2022
 * Authors :
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import styles from './Checkbox.module.scss';
import classNames from 'classnames';
import { forwardRef } from 'react';

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ children, className: externalClassName, ...props }, ref) => {
    return (
      <label className={classNames(styles.checkbox, externalClassName)}>
        <input
          ref={ref}
          className={styles.checkbox__input}
          type="checkbox"
          {...props}
        />
        <span>{children}</span>
      </label>
    );
  }
);

export default Checkbox;
