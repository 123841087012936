import { lazy, Suspense, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Loading from 'src/components/Loading';
import Login from 'src/pages/Login/Login';
import { useFetchEndpointInfo } from 'src/ts/services/endpointInfoService';
import { useLoginStore } from 'src/ts/store/auth';
import { AppTypeEnum, isAppType } from '../ts/enums/appTypeEnum';

const Monitoring = lazy(() => import('src/pages/Monitoring/Monitoring'));

const UnauthenticatedRoutes = () => {
  const isLoggedIn = useLoginStore((state) => state.isLoggedIn);
  const { data: endpointInfoData, loading: endpointInfoLoading } =
    useFetchEndpointInfo();

  const loadingState = useMemo(
    () => endpointInfoLoading,
    [endpointInfoLoading]
  );

  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/monitoring/" element={<Navigate to="/login" />} />
        {(isAppType(AppTypeEnum.MONITORING) ||
          isAppType(AppTypeEnum.CONTRIBUTION)) && (
          <Route
            path="/monitoring/*"
            element={
              !loadingState && (
                <Suspense fallback={<Loading fixedPosition />}>
                  <Monitoring deviceName={endpointInfoData.deviceName} />
                </Suspense>
              )
            }
          />
        )}
        <Route path="/login" element={<Login />} />
        <Route
          path="*"
          element={
            !isLoggedIn ? (
              <Navigate to="/login" />
            ) : (
              <Navigate to="/configure" />
            )
          }
        />
      </Routes>
      {loadingState && <Loading fixedPosition />}
    </div>
  );
};

export default UnauthenticatedRoutes;
