/*
 * File : ToastMessageWrapper.tsx
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import ToastMessage from '../ToastMessage/ToastMessage';

interface ToastMessageWrapperProps {
  errorMessage?: string | null | undefined;
  successMessage?: string | null | undefined;

  warningMessage?: string | null | undefined;
  onCloseError: () => void;
  onCloseSuccess: () => void;
  onCloseWarning: () => void;
}

const ToastMessageWrapper = ({
  children,
  errorMessage,
  successMessage,
  warningMessage,
  onCloseError,
  onCloseSuccess,
  onCloseWarning,
}: React.PropsWithChildren<ToastMessageWrapperProps>) => {
  return (
    <>
      {errorMessage && (
        <ToastMessage
          id={'ToastMessage-Error'}
          message={'ERROR: ' + errorMessage}
          messageType={'error'}
          onClose={onCloseError}
          fixedToWindow
          darkTheme
          closeButton
          fixedPositionAppearAnimation
          timeLimit={10}
        />
      )}
      {successMessage && (
        <ToastMessage
          id={'ToastMessage-Success'}
          message={'SUCCESS: ' + successMessage}
          messageType={'info'}
          onClose={onCloseSuccess}
          fixedToWindow
          darkTheme
          closeButton
          fixedPositionAppearAnimation
          timeLimit={10}
        />
      )}
      {warningMessage && (
        <ToastMessage
          id={'ToastMessage-Warning'}
          message={'WARNING: ' + warningMessage}
          messageType={'warning'}
          onClose={onCloseWarning}
          fixedToWindow
          darkTheme
          closeButton
          fixedPositionAppearAnimation
          timeLimit={10}
        />
      )}
      {children}
    </>
  );
};

export default ToastMessageWrapper;
