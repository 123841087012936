/*
 * File : Login.tsx
 * Created : Aug 2023
 * Authors : Steve Li
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */

import { ReactComponent as CloudLogo } from '../../assets/images/cloud-only-logo.svg';
import { ReactComponent as ConnectMonitorLogo } from 'src/assets/icons/danteConnectLogo/header-logo-dante-monitor-white.svg';
import { ReactComponent as ConnectContributionLogo } from 'src/assets/icons/danteConnectLogo/header-logo-dante-contribution-white.svg';
import { ReactComponent as ConnectBridgeLogo } from 'src/assets/icons/danteConnectLogo/header-logo-dante-monitor-white.svg'; //todo replace with Bridge logo
import styles from './Login.module.scss';
import Input from 'src/components/Input';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import ToastMessage from 'src/components/ToastMessage';
import Button from 'src/components/Button';
import { useForm } from 'react-hook-form';
import { recordTokenToLocalStorage } from 'src/helpers/functions';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserLoginMutation } from 'src/graphql/graphqlGenerated';
import { AppTypeEnum, isAppType } from 'src/ts/enums/appTypeEnum';

export class LoginForm {
  email: string;
  password: string;
}

const Login = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>();
  const navigate = useNavigate();
  const [userLoginAction] = useUserLoginMutation({
    onError: (error) => {
      setErrorMessage(error.message);
    },
    onCompleted: (data) => {
      if (data.userLogin.__typename === 'UserLoginError') {
        setErrorMessage(data.userLogin.message);
      } else if (data.userLogin.__typename === 'UserLoginResult') {
        recordTokenToLocalStorage(data.userLogin.token);
        navigate('/configure');
      }
    },
  });
  const onSubmit = async (d: LoginForm) => {
    await userLoginAction({
      variables: {
        userLoginInput: {
          email: d.email,
          password: d.password,
        },
      },
    });
    return;
  };
  return (
    <div className={styles['login']}>
      <div className={styles['login__logo']}>
        <CloudLogo />
        {isAppType(AppTypeEnum.BRIDGE) ? (
          <ConnectBridgeLogo />
        ) : isAppType(AppTypeEnum.CONTRIBUTION) ? (
          <ConnectContributionLogo />
        ) : (
          <ConnectMonitorLogo />
        )}
      </div>
      <div className={styles['login__card']}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles['login__box']}
        >
          <label htmlFor={'login-email'} className={styles['login__label']}>
            <FormattedMessage id="login.emailLabel" defaultMessage="Email:" />
          </label>
          <Input
            id={'login-email'}
            className={styles['login__input']}
            {...register('email', {
              required: { value: true, message: 'Please insert email address' },
              validate: {
                matchPattern: (v) =>
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                  'Email address must be a valid address',
              },
            })}
          />

          <label
            className={classNames(
              styles['login__label--password'],
              styles['login__label']
            )}
            htmlFor={'login-password'}
          >
            <FormattedMessage
              id="login.passwordLabel"
              defaultMessage="Password:"
            />
          </label>
          <Input
            id={'login-password'}
            className={styles['login__input']}
            type={'password'}
            {...register('password', {
              required: { value: true, message: 'Please insert password' },
              minLength: { value: 3, message: 'Password is incorrect' },
            })}
          />
          <ToastMessage
            message={
              errorMessage || errors.email?.message || errors.password?.message
            }
            messageType={'error'}
            fixedToWindow={false}
            closeButton={false}
            //   onClose={onErrorClose}
          />

          <div className={classNames(styles['login__buttons'])}>
            <Button variant={'primary'} type="submit">
              <FormattedMessage id="login.loginButton" defaultMessage="Login" />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
