/*
 * File : Unlicensed.tsx
 * Created : Aug 2022
 * Authors : Steve Li
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */

import Header from 'src/components/Header';
import styles from './Unlicensed.module.scss';
import { AppTypeEnum, isAppType } from 'src/ts/enums/appTypeEnum';

export const unlicensedErrorMessage = isAppType(AppTypeEnum.BRIDGE)
  ? 'Bridge is either not licensed or not enrolled. Please contact your admin'
  : isAppType(AppTypeEnum.CONTRIBUTION)
  ? 'Remote Contributor is either not licensed or not enrolled. Please contact your admin'
  : 'Remote Monitor is either not licensed or not enrolled. Please contact your admin';

interface UnlicensedProps {
  deviceName: string;
  domainName: string;
}
const Unlicensed = ({ domainName, deviceName }: UnlicensedProps) => {
  return (
    <div className={styles['unlicensed']}>
      <Header deviceName={deviceName} domainName={domainName} />
      <div id={'unlicensedInfo'}>{unlicensedErrorMessage}</div>
    </div>
  );
};

export default Unlicensed;
