/*
 * File : Loading.tsx
 * Created : May 2022
 * Authors :
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import styles from './Loading.module.scss';
import classNames from 'classnames';
interface LoadingProps {
  fixedPosition?: boolean;
}

const Loading = ({ fixedPosition = false }: LoadingProps) => {
  return (
    <div
      className={classNames(
        styles['lds-container'],
        fixedPosition && styles['lds-container__fixed']
      )}
    >
      <div className={classNames(styles['lds-spinner'])}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div>
        <h4>Loading...</h4>
      </div>
    </div>
  );
};

export default Loading;
