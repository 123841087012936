/*
 * File : ContextualHelpWrapper.tsx
 * Created : September 2022
 * Authors :
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import { useContext } from 'react';
import { AuthContext } from 'src/context/authContext';
import ContextualHelp from '../ContextualHelp';

/*
 * File : ContextualHelpWrapper.tsx
 * Created : Aug 2022
 * Authors : Steve Li
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */

const ContextualHelpWrapper = ({ children }: React.PropsWithChildren<{}>) => {
  const context = useContext(AuthContext);
  return (
    <>
      {children}
      {context.contextualMessage && (
        <ContextualHelp
          header={context.contextualMessage.title}
          onClose={() => context.emptyContextualMessage()}
        >
          <p>{context.contextualMessage.message}</p>
        </ContextualHelp>
      )}
    </>
  );
};

export default ContextualHelpWrapper;
