/*
 * File : index.tsx
 * Created : April 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import React from 'react';
import './index.scss';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import client, { backendUri, ifInDevEnvironment } from './apolloClient';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { AuthProvider } from './context/authContext';
import { MobileMenuContextProvider } from './context/mobileMenuContext';

Sentry.init({
  dsn: 'https://c4a702c9921e4bffa7b7c8867378ea80@o990298.ingest.sentry.io/4504948896366592',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // beforeBreadcrumb: excludeGraphQLFetch,
});

function HandleReactRenderError(e: Error) {
  if (backendUri.includes('http://localhost') || ifInDevEnvironment) {
    return;
  }
  console.log('send error to sentry');
  Sentry.captureException(e);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Sentry.ErrorBoundary onError={HandleReactRenderError}>
    <AuthProvider>
      <MobileMenuContextProvider>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </BrowserRouter>
        </ApolloProvider>
      </MobileMenuContextProvider>
    </AuthProvider>
  </Sentry.ErrorBoundary>
);
reportWebVitals();
