/*
 * File : Hamburger.tsx
 * Created : Oct 2022
 * Authors : Steve Li
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import classnames from 'classnames';
import { useContext } from 'react';
import { MobileMenuContext } from 'src/context/mobileMenuContext';
import Modal from '../Modal';
import styles from './Hamburger.module.scss';

const Hamburger = () => {
  const mobileContext = useContext(MobileMenuContext);
  return (
    <>
      <div
        className={classnames(
          styles['hamburger-bar'],
          mobileContext.firstLevelMenuOpen && styles['hamburger-bar__active']
        )}
        onClick={() =>
          mobileContext.setFirstLevelMenuOpen(!mobileContext.firstLevelMenuOpen)
        }
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
      <Modal
        isOpen={mobileContext.firstLevelMenuOpen}
        onIsOpenChange={() =>
          mobileContext.setFirstLevelMenuOpen(!mobileContext.firstLevelMenuOpen)
        }
      ></Modal>
    </>
  );
};

export default Hamburger;
