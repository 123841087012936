/*
 * File : Modal.tsx
 * Created : April 2022
 * Authors :
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import styles from './Modal.module.scss';
import ReactModal, { Props } from 'react-modal';
import classNames from 'classnames';

interface ModalProps extends Props {
  className?: string;
  isOpen: boolean;
  onIsOpenChange: (isOpen: boolean) => void;
}

const Modal = ({
  children,
  className: externalClassName,
  isOpen,
  onIsOpenChange,
  ...rest
}: React.PropsWithChildren<ModalProps>) => {
  return (
    <ReactModal
      {...rest}
      isOpen={isOpen}
      onRequestClose={() => onIsOpenChange(false)}
      className={classNames(styles.modal, externalClassName)}
      overlayClassName={styles.modal__overlay}
    >
      {children}
    </ReactModal>
  );
};

Modal.setAppElement = ReactModal.setAppElement;

export default Modal;
