import { MonitoringConfiguration } from '../../graphql/graphqlGenerated';
import {
  isSameBooleanConfiguration,
  isSameChannelConfiguration,
  isSameRemoteConfiguration,
} from '../../helpers/functions';

// checks for changes only if current configuration is active and previous was already active and both have the same ID, else returns false
export const isActivatedConfigurationStale = (
  currentConfiguration: MonitoringConfiguration,
  previousConfiguration: MonitoringConfiguration | null
): boolean => {
  if (
    currentConfiguration.id &&
    previousConfiguration?.id &&
    currentConfiguration.id === previousConfiguration.id
  ) {
    return hasConfigurationBeenChanged(
      currentConfiguration,
      previousConfiguration
    );
  } else {
    return false;
  }
};

// checks for changes only if current configuration is active, else returns false
export const hasActiveConfigurationBeenChanged = (
  currentConfiguration: MonitoringConfiguration,
  previousConfiguration: MonitoringConfiguration | null
): boolean => {
  if (currentConfiguration.id) {
    return hasConfigurationBeenChanged(
      currentConfiguration,
      previousConfiguration
    );
  } else {
    return false;
  }
};

// compares the current and previous configuration excluding properties irrelevant for the bridge configuration
const hasConfigurationBeenChanged = (
  currentConfiguration: MonitoringConfiguration,
  previousConfiguration: MonitoringConfiguration | null
): boolean => {
  if (currentConfiguration === previousConfiguration) {
    return false;
  }
  if (!previousConfiguration) {
    return true;
  }
  return (
    !isSameRemoteConfiguration(
      currentConfiguration.remoteConfiguration,
      previousConfiguration.remoteConfiguration
    ) ||
    !isSameChannelConfiguration(
      currentConfiguration.rxChannels,
      previousConfiguration.rxChannels
    ) ||
    !isSameChannelConfiguration(
      currentConfiguration.txChannels,
      previousConfiguration.txChannels
    ) ||
    !isSameBooleanConfiguration(
      currentConfiguration.useRxDanteChannelNames,
      previousConfiguration.useRxDanteChannelNames
    ) ||
    !isSameBooleanConfiguration(
      currentConfiguration.useTxDanteChannelNames,
      previousConfiguration.useTxDanteChannelNames
    )
  );
};

// returns true if the current configuration is not active or has a different ID than the previous
export const hasConfigurationBeenDeactivated = (
  currentConfiguration: MonitoringConfiguration,
  previousConfiguration: MonitoringConfiguration | null
): boolean => {
  return (
    !currentConfiguration.id ||
    (previousConfiguration?.id != null &&
      currentConfiguration.id !== previousConfiguration.id)
  );
};
