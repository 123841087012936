/*
 * File : Button.tsx
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import React, { forwardRef } from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'primary' | 'reversed';
  size?: 'sm' | 'lg';
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}

function getVariantStyles(variant: ButtonProps['variant']) {
  if (variant === 'primary' || variant === 'reversed') {
    return styles[`button--style-${variant}`];
  }
  return styles['button--style-default'];
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'default',
      size = 'lg',
      children = 'Button',
      disabled = false,
      className: externalClassName,
      ...props
    },
    ref
  ) => {
    return (
      <button
        type="button"
        className={classNames(
          styles.button,
          size === 'lg' ? styles['button--size-lg'] : styles['button--size-sm'],
          getVariantStyles(variant),
          externalClassName
        )}
        disabled={disabled}
        {...props}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);

export default Button;
