/*
 * File : ToastMessage.tsx
 * Created : May 2022
 * Authors :
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import classnames from 'classnames';
import { forwardRef } from 'react';
import styles from './ToastMessage.module.scss';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';
import { useEffect } from 'react';

interface ToastMessageProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  message: string | undefined | null;
  messageType: 'warning' | 'error' | 'info';
  fixedToWindow: boolean;
  closeButton: boolean;
  onClose?: () => void;
  darkTheme?: boolean;
  fixedPositionAppearAnimation?: boolean;
  timeLimit?: number;
}

const ToastMessage = forwardRef<HTMLDivElement, ToastMessageProps>(
  (
    {
      message,
      messageType,
      fixedToWindow,
      className: externalClassName,
      onClose,
      closeButton,
      darkTheme = false,
      fixedPositionAppearAnimation = false,
      timeLimit,
      ...props
    }: ToastMessageProps,
    ref
  ) => {
    useEffect(() => {
      if (timeLimit && timeLimit !== 0) {
        const timer = setTimeout(() => {
          onClose && onClose();
        }, timeLimit * 1000);
        return () => clearTimeout(timer);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
      <>
        {
          <div
            className={classnames(styles['toast-message-container__container'])}
          >
            <div
              className={classnames(
                styles['toast-message-container'],
                message && styles['toast-message-container__active'],
                fixedToWindow && styles['toast-message-container__fixed'],
                !darkTheme && styles[`toast-message-container--${messageType}`],
                darkTheme &&
                  message &&
                  styles[`toast-message-container--dark-${messageType}`],
                fixedPositionAppearAnimation &&
                  styles['toast-message-container__appear-animation'],
                externalClassName
              )}
              {...props}
              ref={ref}
            >
              <div>{message}</div>
              {closeButton && message && (
                <CloseIcon
                  className={classnames(
                    styles['toast-message-container__close-button']
                  )}
                  onClick={onClose}
                />
              )}
              {timeLimit && timeLimit !== 0 && (
                <div
                  className={styles['toast-message-container__progress']}
                ></div>
              )}
            </div>
          </div>
        }
      </>
    );
  }
);

export default ToastMessage;
