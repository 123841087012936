/*
 * File : ChannelsTable.tsx
 * Created : April 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import React from 'react';
import Table from '../../components/Table';
import { FormattedMessage } from 'react-intl';
import styles from './Configure.module.scss';
import Checkbox from '../../components/Checkbox';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import classNames from 'classnames';
import Input from '../../components/Input';
import {
  ReceiveChannelConfiguration,
  TransmitChannelConfiguration,
} from '../../graphql/graphqlGenerated';
import { ChannelKindEnum } from '../../ts/enums/channelKindEnum';

interface ChannelsTableProps {
  channelKind: ChannelKindEnum;
  channelConfiguration:
    | ReceiveChannelConfiguration[]
    | TransmitChannelConfiguration[];
  useDanteChannelNames: boolean;
  onUseDanteChannelNamesChange: (
    kind: ChannelKindEnum,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onChannelSelectionChange: (
    danteChanelName: string,
    kind: ChannelKindEnum,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onChannelNameChange: (
    danteChanelName: string,
    userName: string,
    kind: ChannelKindEnum
  ) => void;
}

const ChannelsTable = ({
  channelKind,
  channelConfiguration,
  useDanteChannelNames,
  onUseDanteChannelNamesChange,
  onChannelSelectionChange,
  onChannelNameChange,
}: ChannelsTableProps) => {
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Header />
          <Table.Header>
            <FormattedMessage
              id="configureChannels.danteChannelsHeader"
              defaultMessage="Dante channels"
            />
          </Table.Header>
          <Table.Header />
          <Table.Header />
          <Table.Header
            className={styles['configure-channels__remote-channels-header']}
          >
            <span>
              <FormattedMessage
                id="configureChannels.remoteChannelsHeader"
                defaultMessage="Remote channels"
              />
            </span>
            <span>
              <Checkbox
                id="use-dante-channel-names-checkbox"
                className={
                  styles['configure-channels__use-dante-channel-names-checkbox']
                }
                checked={useDanteChannelNames}
                onChange={(e) => onUseDanteChannelNamesChange(channelKind, e)}
              >
                <FormattedMessage
                  id="configureChannels.useDanteChannelNamesHeaderCheckbox"
                  defaultMessage="Use Dante channel names"
                />
              </Checkbox>
            </span>
          </Table.Header>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {channelConfiguration.map((channelConfig, index) => (
          <Table.Row key={`channel-${index}-${channelKind}`}>
            <Table.DataCell>
              <div>
                <span>
                  <Checkbox
                    id={`danteChannel-${index}-checkbox-${channelKind}`}
                    checked={channelConfig.selected}
                    onChange={(e) =>
                      onChannelSelectionChange(
                        channelConfig.danteName,
                        channelKind,
                        e
                      )
                    }
                  />
                </span>
              </div>
            </Table.DataCell>
            <Table.DataCell id={`danteChannel-${index}-${channelKind}`}>
              {channelConfig.danteName}
            </Table.DataCell>
            <Table.DataCell>
              <div>
                {channelKind === ChannelKindEnum.TX ? (
                  <span>
                    <Arrow />
                  </span>
                ) : (
                  <span className={styles['configure-channels__arrow-icon']}>
                    <Arrow />
                  </span>
                )}
              </div>
            </Table.DataCell>
            <Table.DataCell
              className={styles['configure-channels__remote-channel-cell']}
            >
              <span
                className={classNames(
                  styles['configure-channels__remote-channel-index'],
                  (!channelConfig.selected || useDanteChannelNames) &&
                    styles['configure-channels__remote-channel-index__disabled']
                )}
              >
                {index + 1}
              </span>
            </Table.DataCell>
            <Table.DataCell>
              <div>
                <span>
                  <Input
                    id={`remoteChannel-${index}-input-${channelKind}`}
                    className={classNames(
                      styles['configure-channels__remote-channel-input'],
                      styles['configure-channels__input']
                    )}
                    disabled={!channelConfig.selected || useDanteChannelNames}
                    defaultValue={channelConfig.userName ?? ''}
                    onBlur={(e) => {
                      onChannelNameChange(
                        channelConfig.danteName,
                        e.currentTarget.value,
                        channelKind
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onChannelNameChange(
                          channelConfig.danteName,
                          e.currentTarget.value,
                          channelKind
                        );
                      }
                    }}
                  />
                </span>
              </div>
            </Table.DataCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default ChannelsTable;
