/*
 * File : Table.tsx
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
import classnames from 'classnames';
import styles from './Table.module.scss';

interface TableProps extends React.TableHTMLAttributes<HTMLTableElement> {
  variant?: 'default' | 'leftSideHeader';
  header?: React.ReactNode;
  headerRight?: React.ReactNode;
  description?: React.ReactNode;
}

interface TableSectionProps
  extends React.TableHTMLAttributes<HTMLTableSectionElement> {}

interface TableCellProps
  extends React.TableHTMLAttributes<HTMLTableCellElement> {}

interface TableRowProps
  extends React.TableHTMLAttributes<HTMLTableRowElement> {}

const Table = ({
  children,
  className,
  header,
  headerRight,
  description,
  variant = 'default',
  ...rest
}: React.PropsWithChildren<TableProps>) => {
  return (
    <div className={className}>
      {header && (
        <h3
          className={classnames(
            styles['table__header'],
            headerRight && styles['table__header--with-right-content'],
            description && styles['table__header--with-description']
          )}
        >
          {header}
          {headerRight && headerRight}
        </h3>
      )}
      {description && (
        <p className={styles['table__description']}>{description}</p>
      )}
      <table
        className={classnames(
          styles.table,
          variant === 'leftSideHeader' && styles['table--left-side-header']
        )}
        {...rest}
      >
        {children}
      </table>
    </div>
  );
};

const Head = ({
  children,
  className,
  ...rest
}: React.PropsWithChildren<TableSectionProps>) => {
  return (
    <thead className={classnames(styles['table__head'], className)} {...rest}>
      {children}
    </thead>
  );
};

const Header = ({
  children,
  ...rest
}: React.PropsWithChildren<TableCellProps>) => {
  return <th {...rest}>{children}</th>;
};

const Body = ({
  children,
  ...rest
}: React.PropsWithChildren<TableSectionProps>) => {
  return <tbody {...rest}>{children}</tbody>;
};

const Row = ({
  children,
  className,
  ...rest
}: React.PropsWithChildren<TableRowProps>) => {
  return (
    <tr className={classnames(styles['table__row'], className)} {...rest}>
      {children}
    </tr>
  );
};

const DataCell = ({
  children,
  ...rest
}: React.PropsWithChildren<TableCellProps>) => {
  return <td {...rest}>{children}</td>;
};

Table.Head = Head;
Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.DataCell = DataCell;

export default Table;
