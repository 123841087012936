/*
 * File : MaskedInput.tsx
 * Created : July 2024
 * Authors :
 * Synopsis:
 *
 * Copyright 2024 Audinate Pty Ltd and/or its licensors
 *
 */
import styles from './MaskedInput.module.scss';
import classNames from 'classnames';
import { forwardRef, useState } from 'react';
import { ReactComponent as EyeOpen } from '../../assets/icons/eye-show.svg';
import { ReactComponent as EyeClose } from '../../assets/icons/eye-hide.svg';

interface MaskedInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const MaskedInput = forwardRef<HTMLInputElement, MaskedInputProps>(
  ({ className: externalClassName, ...props }, ref) => {
    const [showInput, setShowInput] = useState<boolean>(false);

    return (
      <>
        <input
          {...props}
          type={showInput ? 'text' : 'password'}
          ref={ref}
          className={classNames(styles.input, externalClassName)}
        />
        <button
          {...(props.id ? { id: `btn-${props.id}` } : {})}
          className={classNames(styles.eye)}
          onClick={() => setShowInput((prevState) => !prevState)}
        >
          {showInput ? <EyeClose /> : <EyeOpen />}
        </button>
      </>
    );
  }
);

export default MaskedInput;
