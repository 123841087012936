/*
 * File : authContext.tsx
 * Created : May 2022
 * Authors :
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import React, { createContext, useReducer } from 'react';

type AuthStateType = {
  isLoggedIn: boolean;
  error: string;
  success: string;
  warning: string;
  contextualMessage: ContextualMessageType | undefined;
  modalMessage: ContextualMessageType | undefined;
};

export type ContextualMessageType = {
  title?: string;
  message: string | React.ReactNode;
};

export type AuthContextType = {
  isLoggedIn: boolean;
  error: string;
  success: string;
  warning: string;

  contextualMessage: ContextualMessageType | undefined;
  modalMessage: ContextualMessageType | undefined;
  login: (userInfo: { token: string }) => void;
  logout: () => void;
  setError: (message: string) => void;
  emptyError: () => void;
  setSuccess: (message: string) => void;
  emptySuccess: () => void;
  setWarning: (message: string) => void;
  emptyWarning: () => void;
  setContextualMessage: (info: ContextualMessageType) => void;
  emptyContextualMessage: () => void;
  setModalMessage: (info: ContextualMessageType) => void;
  emptyModalMessage: () => void;
};

const initialState: AuthStateType = {
  isLoggedIn: !!localStorage.getItem('token'),
  error: '',
  success: '',
  warning: '',
  contextualMessage: undefined,
  modalMessage: undefined,
};

const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  error: '',
  success: '',
  warning: '',
  contextualMessage: undefined,
  modalMessage: undefined,
  login: (userInfo: { token: string }) => {},
  logout: () => {},
  setError: (message: string) => {},
  emptyError: () => {},
  setSuccess: (message: string) => {},
  emptySuccess: () => {},
  setWarning: (message: string) => {},
  emptyWarning: () => {},
  setContextualMessage: (info: ContextualMessageType) => {},
  emptyContextualMessage: () => {},
  setModalMessage: (info: ContextualMessageType) => {},
  emptyModalMessage: () => {},
});

function authReducer(state: AuthStateType, action: AuthActionType) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case 'LOGOUT':
      return {
        ...state,
        isLoggedin: false,
      };
    case 'SETERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'EMPTYERROR':
      return {
        ...state,
        error: '',
      };
    case 'SETSUCCESS':
      return {
        ...state,
        success: action.payload,
      };
    case 'EMPTYSUCCESS':
      return {
        ...state,
        success: '',
      };
    case 'SETWARNING':
      return {
        ...state,
        warning: action.payload,
      };
    case 'EMPTYWARNING':
      return {
        ...state,
        warning: '',
      };
    case 'SETCONTEXTUAL':
      return {
        ...state,
        contextualMessage: {
          title: action.payload.title,
          message: action.payload.message,
        },
      };
    case 'EMPTYCONTEXTUAL':
      return {
        ...state,
        contextualMessage: undefined,
      };
    case 'SETMODAL':
      return {
        ...state,
        modalMessage: {
          title: action.payload.title,
          message: action.payload.message,
        },
      };
    case 'EMPTYMODAL':
      return {
        ...state,
        modalMessage: undefined,
      };
    default:
      return state;
  }
}

type AuthActionType =
  | loginActionType
  | logoutActionType
  | setErrorActionType
  | emptyErrorActionType
  | setSuccessActionType
  | emptySuccessActionType
  | setWarningActionType
  | emptyWarningActionType
  | setContextualMessageType
  | emptyContextualMessageType
  | setModalMessageType
  | emptyModalMessageType;
type loginActionType = {
  type: 'LOGIN';
  payload: boolean;
};
type logoutActionType = {
  type: 'LOGOUT';
};
type setErrorActionType = {
  type: 'SETERROR';
  payload: string;
};
type emptyErrorActionType = {
  type: 'EMPTYERROR';
};
type setSuccessActionType = {
  type: 'SETSUCCESS';
  payload: string;
};
type emptySuccessActionType = {
  type: 'EMPTYSUCCESS';
};
type setWarningActionType = {
  type: 'SETWARNING';
  payload: string;
};
type emptyWarningActionType = {
  type: 'EMPTYWARNING';
};
type setContextualMessageType = {
  type: 'SETCONTEXTUAL';
  payload: ContextualMessageType;
};
type emptyContextualMessageType = {
  type: 'EMPTYCONTEXTUAL';
};
type setModalMessageType = {
  type: 'SETMODAL';
  payload: ContextualMessageType;
};
type emptyModalMessageType = {
  type: 'EMPTYMODAL';
};

function AuthProvider(props: any) {
  const [state, dispatch] = useReducer<
    (state: AuthStateType, action: AuthActionType) => AuthStateType
  >(authReducer, initialState);

  const login = (userInfo: { token: string }) => {
    localStorage.setItem('token', userInfo.token);
    dispatch({
      type: 'LOGIN',
      payload: !!userInfo.token,
    });
  };
  const logout = () => {
    localStorage.removeItem('token');
    dispatch({ type: 'LOGOUT' });
  };
  const setError = (message: string) => {
    dispatch({ type: 'SETERROR', payload: message });
  };
  const emptyError = () => {
    dispatch({ type: 'EMPTYERROR' });
  };
  const setSuccess = (message: string) => {
    dispatch({ type: 'SETSUCCESS', payload: message });
  };
  const emptySuccess = () => {
    dispatch({ type: 'EMPTYSUCCESS' });
  };

  const setWarning = (message: string) => {
    dispatch({ type: 'SETWARNING', payload: message });
  };
  const emptyWarning = () => {
    dispatch({ type: 'EMPTYWARNING' });
  };

  const setContextualMessage = (info: ContextualMessageType) => {
    dispatch({ type: 'SETCONTEXTUAL', payload: info });
  };
  const emptyContextualMessage = () => {
    dispatch({ type: 'EMPTYCONTEXTUAL' });
  };
  const setModalMessage = (info: ContextualMessageType) => {
    dispatch({ type: 'SETMODAL', payload: info });
  };
  const emptyModalMessage = () => {
    dispatch({ type: 'EMPTYMODAL' });
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: state.isLoggedIn,
        error: state.error,
        success: state.success,
        warning: state.warning,
        contextualMessage: state.contextualMessage,
        modalMessage: state.modalMessage,
        login,
        logout,
        setError,
        emptyError,
        setSuccess,
        emptySuccess,
        setWarning,
        emptyWarning,
        setContextualMessage,
        emptyContextualMessage,
        setModalMessage,
        emptyModalMessage,
      }}
      {...props}
    />
  );
}

export { AuthContext, AuthProvider };
