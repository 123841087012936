/*
 * File : apolloClient.ts
 * Created : May 2022
 * Authors :
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { onError } from '@apollo/client/link/error';

import * as Sentry from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';
import { getUserLoginInfo, userLogoutAction } from './helpers/functions';

// @ts-ignore
const configurationData = window['runtimeConfig'];
export const backendUri = configurationData.GRAPHQL_SERVICE_ADDRESS
  ? configurationData.GRAPHQL_SERVICE_ADDRESS
  : `http://localhost:4000/graphql`;

const sentryLink = new SentryLink({
  attachBreadcrumbs: {
    includeError: true,
    includeQuery: true,
  },
  uri: backendUri,
});
const backendUriComponent = backendUri.split('.');
export const ifInDevEnvironment =
  backendUriComponent.length > 1 && backendUriComponent[1] === 'dev';

const onErrorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors?.find((e) => e.extensions?.code === 'UNAUTHENTICATED')) {
    // user did not authenticate or authentication token is not correct, we logout user
    userLogoutAction();
    return;
  }
  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => {
      // filter out development environment
      if (backendUri.includes('http://localhost') || ifInDevEnvironment) {
        return;
      }

      console.log('send error to sentry');
      Sentry.addBreadcrumb({
        category: 'onErrorLink.graphql.operation',
        data: operation,
      });

      Sentry.addBreadcrumb({
        category: 'onErrorLink.graphql.error',
        data: graphQLError,
      });

      Sentry.captureException(new Error(graphQLError.message));
    });
  }
  // specific message change, in case we see other network error messages
  if (networkError?.message === 'Failed to fetch') {
    networkError.message = 'Unable to connect to server';
  } else if (networkError) {
    if (backendUri.includes('http://localhost') || ifInDevEnvironment) {
      return;
    }
    console.log('send error to sentry');
    Sentry.captureException(networkError);
  }
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: getUserLoginInfo() || '',
    },
  };
});

const httpLink = createHttpLink({
  uri: backendUri,
});

const client = new ApolloClient({
  link: ApolloLink.from([sentryLink, onErrorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default client;
