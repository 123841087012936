/*
 * File : Input.tsx
 * Created : April 2022
 * Authors :
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import styles from './Input.module.scss';
import classNames from 'classnames';
import { forwardRef } from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className: externalClassName, ...props }, ref) => {
    return (
      <input
        {...props}
        ref={ref}
        className={classNames(styles.input, externalClassName)}
      />
    );
  }
);

export default Input;
