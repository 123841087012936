/*
 * File : channelKindEnum.ts
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
export enum ChannelKindEnum {
  RX,
  TX,
}
