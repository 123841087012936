/*
 * File : Page404.tsx
 * Created : May 2022
 * Authors :
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import styles from './Page404.module.scss';

const Page404 = () => {
  return <div className={styles['Page404']}>404 Cannot Find the Content</div>;
};

export default Page404;
