/*
 * File : appTypeEnum.ts
 * Created : March 2023
 * Authors :
 * Synopsis:
 *
 * Copyright 2023 Audinate Pty Ltd and/or its licensors
 *
 */
export enum AppTypeEnum {
  MONITORING = 'monitoring',
  CONTRIBUTION = 'contribution',
  BRIDGE = 'bridge',
}

const resolveAppType = (): AppTypeEnum | null => {
  const givenAppType = process.env.REACT_APP_TYPE;
  if (givenAppType === AppTypeEnum.CONTRIBUTION) {
    return AppTypeEnum.CONTRIBUTION;
  } else if (givenAppType === AppTypeEnum.MONITORING) {
    return AppTypeEnum.MONITORING;
  } else if (givenAppType === AppTypeEnum.BRIDGE) {
    return AppTypeEnum.BRIDGE;
  } else {
    return null;
  }
};

export const isAppType = (appTypeToBeCheck: AppTypeEnum) => {
  return appTypeToBeCheck === resolveAppType();
};
