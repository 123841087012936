/*
 * File : SidebarItem.tsx
 * Created : May 2022
 * Authors :
 * Synopsis:
 *
 * Copyright 2022 Audinate Pty Ltd and/or its licensors
 *
 */
import styles from '../Sidebar.module.scss';
import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import { Link, useLocation } from 'react-router-dom';

export interface SidebarItemProps {
  href: string;
  icon?: string | React.ReactNode;
  label: React.ReactNode;
  statusIcon?: React.ReactNode;
  active?: boolean;
  expanded?: boolean;
  // only for Storybook links
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
}

const SidebarItem = ({
  href,
  icon,
  label,
  statusIcon,
  active = false,
  expanded = false,
  className: externalClassName,
  onClick = () => {},
}: SidebarItemProps) => {
  let isActive: boolean = false;
  const location = useLocation();
  let activePathName = location.pathname.toLowerCase();
  let activeHref = href.toLocaleLowerCase();
  isActive = activePathName.includes(activeHref);
  return (
    <Link
      className={classNames(
        styles['sidebar-item'],
        expanded && styles['sidebar-item--expanded'],
        (isActive || active) && styles['sidebar-item--active'],
        externalClassName
      )}
      to={href}
      onClick={onClick}
      data-testid={href}
    >
      <div className={styles['sidebar-item__icon']}>{icon}</div>
      <div className={styles['sidebar-item__label']}>{label}</div>
      {statusIcon && (
        <div className={styles['sidebar-item__status-icon']}>{statusIcon}</div>
      )}
    </Link>
  );
};

export default SidebarItem;
