import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type EndpointInfo = {
  __typename?: 'EndpointInfo';
  endpointDanteDomain?: Maybe<Scalars['String']['output']>;
  endpointDeviceName?: Maybe<Scalars['String']['output']>;
  licenseActivated: Scalars['Boolean']['output'];
};

export type EndpointInfoNotFoundError = Error & {
  __typename?: 'EndpointInfoNotFoundError';
  licenseActivated: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
};

export type Error = {
  message: Scalars['String']['output'];
};

export type GraphqlActivateMonitoringConfigurationResult =
  | MonitoringConfiguration
  | MonitoringConfigurationAlreadyActiveError
  | MonitoringConfigurationInvalidError
  | MonitoringConfigurationNotFoundError;

export type GraphqlDeactivateMonitoringConfigurationResult =
  | MonitoringConfiguration
  | MonitoringConfigurationNotFoundError;

export type GraphqlGetEndpointInfoResult =
  | EndpointInfo
  | EndpointInfoNotFoundError;

export type GraphqlGetMonitoringConfigurationResult =
  | MonitoringConfiguration
  | MonitoringConfigurationNotFoundError;

export type GraphqlGetRtcConfigurationResult =
  | RtcConfigurationJson
  | RtcConfigurationNotFoundError;

export type GraphqlUpdateMonitoringConfigurationResult =
  | MonitoringConfiguration
  | MonitoringConfigurationNotFoundError;

export type GraphqlUserLoginInfoResult = UserLoginError | UserLoginResult;

export type MonitoringConfiguration = {
  __typename?: 'MonitoringConfiguration';
  clientConnected?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  remoteConfiguration?: Maybe<RemoteConfiguration>;
  rxChannels?: Maybe<Array<ReceiveChannelConfiguration>>;
  txChannels?: Maybe<Array<TransmitChannelConfiguration>>;
  useRxDanteChannelNames?: Maybe<Scalars['Boolean']['output']>;
  useTxDanteChannelNames?: Maybe<Scalars['Boolean']['output']>;
};

export type MonitoringConfigurationAlreadyActiveError = Error & {
  __typename?: 'MonitoringConfigurationAlreadyActiveError';
  message: Scalars['String']['output'];
};

export type MonitoringConfigurationInvalidError = Error & {
  __typename?: 'MonitoringConfigurationInvalidError';
  message: Scalars['String']['output'];
};

export type MonitoringConfigurationNotFoundError = Error & {
  __typename?: 'MonitoringConfigurationNotFoundError';
  message: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateMonitoringConfiguration: GraphqlActivateMonitoringConfigurationResult;
  deactivateMonitoringConfiguration: GraphqlDeactivateMonitoringConfigurationResult;
  updateMonitoringConfiguration: GraphqlUpdateMonitoringConfigurationResult;
  userLogin: GraphqlUserLoginInfoResult;
};

export type MutationDeactivateMonitoringConfigurationArgs = {
  id: Scalars['String']['input'];
};

export type MutationUpdateMonitoringConfigurationArgs = {
  updateMonitoringConfigurationInput: UpdateMonitoringConfigurationInput;
};

export type MutationUserLoginArgs = {
  userLoginInput: UserLoginInput;
};

export type Query = {
  __typename?: 'Query';
  endpointInfo: GraphqlGetEndpointInfoResult;
  monitoringConfiguration: GraphqlGetMonitoringConfigurationResult;
  remoteClients: Array<RemoteClient>;
  rtcConfiguration: GraphqlGetRtcConfigurationResult;
};

export type QueryMonitoringConfigurationArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type RtcConfigurationJson = {
  __typename?: 'RTCConfigurationJSON';
  json: Scalars['String']['output'];
};

export type RtcConfigurationNotFoundError = Error & {
  __typename?: 'RTCConfigurationNotFoundError';
  message: Scalars['String']['output'];
};

export type ReceiveChannelConfiguration = {
  __typename?: 'ReceiveChannelConfiguration';
  danteName: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
  userName?: Maybe<Scalars['String']['output']>;
};

export type ReceiveChannelConfigurationModelInput = {
  danteChannelName: Scalars['String']['input'];
  selected: Scalars['Boolean']['input'];
  userChannelName?: InputMaybe<Scalars['String']['input']>;
};

export type RemoteClient = {
  __typename?: 'RemoteClient';
  ipAddress?: Maybe<Scalars['String']['output']>;
  metrics?: Maybe<RemoteClientMetrics>;
  signalStatus?: Maybe<SignalStatus>;
  status?: Maybe<Scalars['String']['output']>;
};

export type RemoteClientMetrics = {
  __typename?: 'RemoteClientMetrics';
  receiveBufferOverruns: Scalars['Float']['output'];
  receiveBufferTargetInMs: Scalars['Float']['output'];
  receiveBufferUnderruns: Scalars['Float']['output'];
  receiveHighestRms: Scalars['Float']['output'];
  receiveInsertedSamplesForDeceleration: Scalars['Float']['output'];
  receiveJitterInMs: Scalars['Float']['output'];
  receiveLastSequenceNumber: Scalars['Float']['output'];
  receiveMissingPackets: Scalars['Float']['output'];
  receiveNumberOfChannels: Scalars['Float']['output'];
  receiveOutOfOrderPackets: Scalars['Float']['output'];
  receiveRemovedSamplesForAcceleration: Scalars['Float']['output'];
};

export type RemoteConfiguration = {
  __typename?: 'RemoteConfiguration';
  dnsNameOrIpAddress?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
};

export type RemoteConfigurationModelInput = {
  dnsNameOrIpAddress?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export enum SignalStatus {
  Audio = 'AUDIO',
  Clipping = 'CLIPPING',
  NoAudio = 'NO_AUDIO',
}

export type TransmitChannelConfiguration = {
  __typename?: 'TransmitChannelConfiguration';
  danteName: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
  userName?: Maybe<Scalars['String']['output']>;
};

export type TransmitChannelConfigurationModelInput = {
  danteChannelName: Scalars['String']['input'];
  selected: Scalars['Boolean']['input'];
  userChannelName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMonitoringConfigurationInput = {
  remoteConfiguration?: InputMaybe<RemoteConfigurationModelInput>;
  rxChannels?: InputMaybe<Array<ReceiveChannelConfigurationModelInput>>;
  txChannels?: InputMaybe<Array<TransmitChannelConfigurationModelInput>>;
  useRxDanteChannelNames?: InputMaybe<Scalars['Boolean']['input']>;
  useTxDanteChannelNames?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserLoginError = Error & {
  __typename?: 'UserLoginError';
  message: Scalars['String']['output'];
  ok: Scalars['Boolean']['output'];
};

export type UserLoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type UserLoginResult = {
  __typename?: 'UserLoginResult';
  ok: Scalars['Boolean']['output'];
  token: Scalars['String']['output'];
};

export type MutationActivateMonitoringConfigurationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type MutationActivateMonitoringConfigurationMutation = {
  __typename?: 'Mutation';
  activateMonitoringConfiguration:
    | {
        __typename: 'MonitoringConfiguration';
        id?: string | null;
        useRxDanteChannelNames?: boolean | null;
        useTxDanteChannelNames?: boolean | null;
        clientConnected?: boolean | null;
        rxChannels?: Array<{
          __typename: 'ReceiveChannelConfiguration';
          danteName: string;
          userName?: string | null;
          selected: boolean;
        }> | null;
        txChannels?: Array<{
          __typename: 'TransmitChannelConfiguration';
          danteName: string;
          userName?: string | null;
          selected: boolean;
        }> | null;
        remoteConfiguration?: {
          __typename: 'RemoteConfiguration';
          dnsNameOrIpAddress?: string | null;
          password?: string | null;
        } | null;
      }
    | {
        __typename: 'MonitoringConfigurationAlreadyActiveError';
        message: string;
      }
    | { __typename: 'MonitoringConfigurationInvalidError'; message: string }
    | { __typename: 'MonitoringConfigurationNotFoundError'; message: string };
};

export type MutationDeactivateMonitoringConfigurationMutationVariables = Exact<{
  deactivateMonitoringConfigurationId: Scalars['String']['input'];
}>;

export type MutationDeactivateMonitoringConfigurationMutation = {
  __typename?: 'Mutation';
  deactivateMonitoringConfiguration:
    | {
        __typename: 'MonitoringConfiguration';
        id?: string | null;
        useRxDanteChannelNames?: boolean | null;
        useTxDanteChannelNames?: boolean | null;
        clientConnected?: boolean | null;
        rxChannels?: Array<{
          __typename: 'ReceiveChannelConfiguration';
          danteName: string;
          userName?: string | null;
          selected: boolean;
        }> | null;
        txChannels?: Array<{
          __typename: 'TransmitChannelConfiguration';
          danteName: string;
          userName?: string | null;
          selected: boolean;
        }> | null;
        remoteConfiguration?: {
          __typename: 'RemoteConfiguration';
          dnsNameOrIpAddress?: string | null;
          password?: string | null;
        } | null;
      }
    | { __typename: 'MonitoringConfigurationNotFoundError'; message: string };
};

export type GetEndpointInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetEndpointInfoQuery = {
  __typename?: 'Query';
  endpointInfo:
    | {
        __typename: 'EndpointInfo';
        endpointDanteDomain?: string | null;
        endpointDeviceName?: string | null;
        licenseActivated: boolean;
      }
    | {
        __typename: 'EndpointInfoNotFoundError';
        message: string;
        licenseActivated: boolean;
      };
};

export type GetMonitoringConfigurationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMonitoringConfigurationQuery = {
  __typename?: 'Query';
  monitoringConfiguration:
    | {
        __typename: 'MonitoringConfiguration';
        id?: string | null;
        useRxDanteChannelNames?: boolean | null;
        useTxDanteChannelNames?: boolean | null;
        clientConnected?: boolean | null;
        rxChannels?: Array<{
          __typename: 'ReceiveChannelConfiguration';
          danteName: string;
          userName?: string | null;
          selected: boolean;
        }> | null;
        txChannels?: Array<{
          __typename: 'TransmitChannelConfiguration';
          danteName: string;
          userName?: string | null;
          selected: boolean;
        }> | null;
        remoteConfiguration?: {
          __typename: 'RemoteConfiguration';
          dnsNameOrIpAddress?: string | null;
          password?: string | null;
        } | null;
      }
    | { __typename: 'MonitoringConfigurationNotFoundError'; message: string };
};

export type GetMonitoringConfigurationByIdQueryVariables = Exact<{
  monitoringConfigurationId: Scalars['String']['input'];
}>;

export type GetMonitoringConfigurationByIdQuery = {
  __typename?: 'Query';
  monitoringConfiguration:
    | {
        __typename: 'MonitoringConfiguration';
        id?: string | null;
        useRxDanteChannelNames?: boolean | null;
        useTxDanteChannelNames?: boolean | null;
        clientConnected?: boolean | null;
        rxChannels?: Array<{
          __typename: 'ReceiveChannelConfiguration';
          danteName: string;
          userName?: string | null;
          selected: boolean;
        }> | null;
        txChannels?: Array<{
          __typename: 'TransmitChannelConfiguration';
          danteName: string;
          userName?: string | null;
          selected: boolean;
        }> | null;
        remoteConfiguration?: {
          __typename: 'RemoteConfiguration';
          dnsNameOrIpAddress?: string | null;
          password?: string | null;
        } | null;
      }
    | { __typename: 'MonitoringConfigurationNotFoundError'; message: string };
};

export type GetRemoteClientsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRemoteClientsQuery = {
  __typename?: 'Query';
  remoteClients: Array<{
    __typename: 'RemoteClient';
    ipAddress?: string | null;
    status?: string | null;
    signalStatus?: SignalStatus | null;
    metrics?: {
      __typename: 'RemoteClientMetrics';
      receiveNumberOfChannels: number;
      receiveOutOfOrderPackets: number;
      receiveMissingPackets: number;
      receiveBufferOverruns: number;
      receiveBufferUnderruns: number;
      receiveRemovedSamplesForAcceleration: number;
      receiveInsertedSamplesForDeceleration: number;
      receiveLastSequenceNumber: number;
      receiveJitterInMs: number;
      receiveBufferTargetInMs: number;
      receiveHighestRms: number;
    } | null;
  }>;
};

export type GetRtcConfigurationQueryVariables = Exact<{ [key: string]: never }>;

export type GetRtcConfigurationQuery = {
  __typename?: 'Query';
  rtcConfiguration:
    | { __typename: 'RTCConfigurationJSON'; json: string }
    | { __typename: 'RTCConfigurationNotFoundError'; message: string };
};

export type MutationUpdateMonitoringConfigurationMutationVariables = Exact<{
  updateMonitoringConfigurationInput: UpdateMonitoringConfigurationInput;
}>;

export type MutationUpdateMonitoringConfigurationMutation = {
  __typename?: 'Mutation';
  updateMonitoringConfiguration:
    | {
        __typename?: 'MonitoringConfiguration';
        id?: string | null;
        useRxDanteChannelNames?: boolean | null;
        useTxDanteChannelNames?: boolean | null;
        rxChannels?: Array<{
          __typename?: 'ReceiveChannelConfiguration';
          danteName: string;
          userName?: string | null;
          selected: boolean;
        }> | null;
        txChannels?: Array<{
          __typename?: 'TransmitChannelConfiguration';
          danteName: string;
          userName?: string | null;
          selected: boolean;
        }> | null;
        remoteConfiguration?: {
          __typename?: 'RemoteConfiguration';
          dnsNameOrIpAddress?: string | null;
          password?: string | null;
        } | null;
      }
    | { __typename?: 'MonitoringConfigurationNotFoundError'; message: string };
};

export type UserLoginMutationVariables = Exact<{
  userLoginInput: UserLoginInput;
}>;

export type UserLoginMutation = {
  __typename?: 'Mutation';
  userLogin:
    | { __typename?: 'UserLoginError'; message: string; ok: boolean }
    | { __typename?: 'UserLoginResult'; ok: boolean; token: string };
};

export const MutationActivateMonitoringConfigurationDocument = gql`
  mutation mutationActivateMonitoringConfiguration {
    activateMonitoringConfiguration {
      ... on MonitoringConfiguration {
        __typename
        id
        useRxDanteChannelNames
        useTxDanteChannelNames
        clientConnected
        rxChannels {
          __typename
          danteName
          userName
          selected
        }
        txChannels {
          __typename
          danteName
          userName
          selected
        }
        remoteConfiguration {
          __typename
          dnsNameOrIpAddress
          password
        }
      }
      ... on MonitoringConfigurationInvalidError {
        __typename
        message
      }
      ... on MonitoringConfigurationAlreadyActiveError {
        __typename
        message
      }
      ... on MonitoringConfigurationNotFoundError {
        __typename
        message
      }
    }
  }
`;
export type MutationActivateMonitoringConfigurationMutationFn =
  Apollo.MutationFunction<
    MutationActivateMonitoringConfigurationMutation,
    MutationActivateMonitoringConfigurationMutationVariables
  >;

/**
 * __useMutationActivateMonitoringConfigurationMutation__
 *
 * To run a mutation, you first call `useMutationActivateMonitoringConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationActivateMonitoringConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationActivateMonitoringConfigurationMutation, { data, loading, error }] = useMutationActivateMonitoringConfigurationMutation({
 *   variables: {
 *   },
 * });
 */
export function useMutationActivateMonitoringConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MutationActivateMonitoringConfigurationMutation,
    MutationActivateMonitoringConfigurationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MutationActivateMonitoringConfigurationMutation,
    MutationActivateMonitoringConfigurationMutationVariables
  >(MutationActivateMonitoringConfigurationDocument, options);
}
export type MutationActivateMonitoringConfigurationMutationHookResult =
  ReturnType<typeof useMutationActivateMonitoringConfigurationMutation>;
export type MutationActivateMonitoringConfigurationMutationResult =
  Apollo.MutationResult<MutationActivateMonitoringConfigurationMutation>;
export type MutationActivateMonitoringConfigurationMutationOptions =
  Apollo.BaseMutationOptions<
    MutationActivateMonitoringConfigurationMutation,
    MutationActivateMonitoringConfigurationMutationVariables
  >;
export const MutationDeactivateMonitoringConfigurationDocument = gql`
  mutation mutationDeactivateMonitoringConfiguration(
    $deactivateMonitoringConfigurationId: String!
  ) {
    deactivateMonitoringConfiguration(
      id: $deactivateMonitoringConfigurationId
    ) {
      ... on MonitoringConfiguration {
        __typename
        id
        useRxDanteChannelNames
        useTxDanteChannelNames
        clientConnected
        rxChannels {
          __typename
          danteName
          userName
          selected
        }
        txChannels {
          __typename
          danteName
          userName
          selected
        }
        remoteConfiguration {
          __typename
          dnsNameOrIpAddress
          password
        }
      }
      ... on MonitoringConfigurationNotFoundError {
        __typename
        message
      }
    }
  }
`;
export type MutationDeactivateMonitoringConfigurationMutationFn =
  Apollo.MutationFunction<
    MutationDeactivateMonitoringConfigurationMutation,
    MutationDeactivateMonitoringConfigurationMutationVariables
  >;

/**
 * __useMutationDeactivateMonitoringConfigurationMutation__
 *
 * To run a mutation, you first call `useMutationDeactivateMonitoringConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationDeactivateMonitoringConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationDeactivateMonitoringConfigurationMutation, { data, loading, error }] = useMutationDeactivateMonitoringConfigurationMutation({
 *   variables: {
 *      deactivateMonitoringConfigurationId: // value for 'deactivateMonitoringConfigurationId'
 *   },
 * });
 */
export function useMutationDeactivateMonitoringConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MutationDeactivateMonitoringConfigurationMutation,
    MutationDeactivateMonitoringConfigurationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MutationDeactivateMonitoringConfigurationMutation,
    MutationDeactivateMonitoringConfigurationMutationVariables
  >(MutationDeactivateMonitoringConfigurationDocument, options);
}
export type MutationDeactivateMonitoringConfigurationMutationHookResult =
  ReturnType<typeof useMutationDeactivateMonitoringConfigurationMutation>;
export type MutationDeactivateMonitoringConfigurationMutationResult =
  Apollo.MutationResult<MutationDeactivateMonitoringConfigurationMutation>;
export type MutationDeactivateMonitoringConfigurationMutationOptions =
  Apollo.BaseMutationOptions<
    MutationDeactivateMonitoringConfigurationMutation,
    MutationDeactivateMonitoringConfigurationMutationVariables
  >;
export const GetEndpointInfoDocument = gql`
  query getEndpointInfo {
    endpointInfo {
      ... on EndpointInfo {
        __typename
        endpointDanteDomain
        endpointDeviceName
        licenseActivated
      }
      ... on EndpointInfoNotFoundError {
        message
        licenseActivated
        __typename
      }
    }
  }
`;

/**
 * __useGetEndpointInfoQuery__
 *
 * To run a query within a React component, call `useGetEndpointInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndpointInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndpointInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEndpointInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEndpointInfoQuery,
    GetEndpointInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEndpointInfoQuery, GetEndpointInfoQueryVariables>(
    GetEndpointInfoDocument,
    options
  );
}
export function useGetEndpointInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEndpointInfoQuery,
    GetEndpointInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEndpointInfoQuery,
    GetEndpointInfoQueryVariables
  >(GetEndpointInfoDocument, options);
}
export type GetEndpointInfoQueryHookResult = ReturnType<
  typeof useGetEndpointInfoQuery
>;
export type GetEndpointInfoLazyQueryHookResult = ReturnType<
  typeof useGetEndpointInfoLazyQuery
>;
export type GetEndpointInfoQueryResult = Apollo.QueryResult<
  GetEndpointInfoQuery,
  GetEndpointInfoQueryVariables
>;
export const GetMonitoringConfigurationDocument = gql`
  query getMonitoringConfiguration {
    monitoringConfiguration {
      ... on MonitoringConfiguration {
        __typename
        id
        useRxDanteChannelNames
        useTxDanteChannelNames
        clientConnected
        rxChannels {
          __typename
          danteName
          userName
          selected
        }
        txChannels {
          __typename
          danteName
          userName
          selected
        }
        remoteConfiguration {
          __typename
          dnsNameOrIpAddress
          password
        }
      }
      ... on MonitoringConfigurationNotFoundError {
        __typename
        message
      }
    }
  }
`;

/**
 * __useGetMonitoringConfigurationQuery__
 *
 * To run a query within a React component, call `useGetMonitoringConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonitoringConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonitoringConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMonitoringConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMonitoringConfigurationQuery,
    GetMonitoringConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMonitoringConfigurationQuery,
    GetMonitoringConfigurationQueryVariables
  >(GetMonitoringConfigurationDocument, options);
}
export function useGetMonitoringConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMonitoringConfigurationQuery,
    GetMonitoringConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMonitoringConfigurationQuery,
    GetMonitoringConfigurationQueryVariables
  >(GetMonitoringConfigurationDocument, options);
}
export type GetMonitoringConfigurationQueryHookResult = ReturnType<
  typeof useGetMonitoringConfigurationQuery
>;
export type GetMonitoringConfigurationLazyQueryHookResult = ReturnType<
  typeof useGetMonitoringConfigurationLazyQuery
>;
export type GetMonitoringConfigurationQueryResult = Apollo.QueryResult<
  GetMonitoringConfigurationQuery,
  GetMonitoringConfigurationQueryVariables
>;
export const GetMonitoringConfigurationByIdDocument = gql`
  query getMonitoringConfigurationById($monitoringConfigurationId: String!) {
    monitoringConfiguration(id: $monitoringConfigurationId) {
      ... on MonitoringConfiguration {
        __typename
        id
        useRxDanteChannelNames
        useTxDanteChannelNames
        clientConnected
        rxChannels {
          __typename
          danteName
          userName
          selected
        }
        txChannels {
          __typename
          danteName
          userName
          selected
        }
        remoteConfiguration {
          __typename
          dnsNameOrIpAddress
          password
        }
      }
      ... on MonitoringConfigurationNotFoundError {
        __typename
        message
      }
    }
  }
`;

/**
 * __useGetMonitoringConfigurationByIdQuery__
 *
 * To run a query within a React component, call `useGetMonitoringConfigurationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonitoringConfigurationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonitoringConfigurationByIdQuery({
 *   variables: {
 *      monitoringConfigurationId: // value for 'monitoringConfigurationId'
 *   },
 * });
 */
export function useGetMonitoringConfigurationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMonitoringConfigurationByIdQuery,
    GetMonitoringConfigurationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMonitoringConfigurationByIdQuery,
    GetMonitoringConfigurationByIdQueryVariables
  >(GetMonitoringConfigurationByIdDocument, options);
}
export function useGetMonitoringConfigurationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMonitoringConfigurationByIdQuery,
    GetMonitoringConfigurationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMonitoringConfigurationByIdQuery,
    GetMonitoringConfigurationByIdQueryVariables
  >(GetMonitoringConfigurationByIdDocument, options);
}
export type GetMonitoringConfigurationByIdQueryHookResult = ReturnType<
  typeof useGetMonitoringConfigurationByIdQuery
>;
export type GetMonitoringConfigurationByIdLazyQueryHookResult = ReturnType<
  typeof useGetMonitoringConfigurationByIdLazyQuery
>;
export type GetMonitoringConfigurationByIdQueryResult = Apollo.QueryResult<
  GetMonitoringConfigurationByIdQuery,
  GetMonitoringConfigurationByIdQueryVariables
>;
export const GetRemoteClientsDocument = gql`
  query getRemoteClients {
    remoteClients {
      __typename
      ipAddress
      status
      signalStatus
      metrics {
        __typename
        receiveNumberOfChannels
        receiveOutOfOrderPackets
        receiveMissingPackets
        receiveBufferOverruns
        receiveBufferUnderruns
        receiveRemovedSamplesForAcceleration
        receiveInsertedSamplesForDeceleration
        receiveLastSequenceNumber
        receiveJitterInMs
        receiveBufferTargetInMs
        receiveHighestRms
      }
    }
  }
`;

/**
 * __useGetRemoteClientsQuery__
 *
 * To run a query within a React component, call `useGetRemoteClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRemoteClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRemoteClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRemoteClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRemoteClientsQuery,
    GetRemoteClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRemoteClientsQuery, GetRemoteClientsQueryVariables>(
    GetRemoteClientsDocument,
    options
  );
}
export function useGetRemoteClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRemoteClientsQuery,
    GetRemoteClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRemoteClientsQuery,
    GetRemoteClientsQueryVariables
  >(GetRemoteClientsDocument, options);
}
export type GetRemoteClientsQueryHookResult = ReturnType<
  typeof useGetRemoteClientsQuery
>;
export type GetRemoteClientsLazyQueryHookResult = ReturnType<
  typeof useGetRemoteClientsLazyQuery
>;
export type GetRemoteClientsQueryResult = Apollo.QueryResult<
  GetRemoteClientsQuery,
  GetRemoteClientsQueryVariables
>;
export const GetRtcConfigurationDocument = gql`
  query getRtcConfiguration {
    rtcConfiguration {
      ... on RTCConfigurationJSON {
        __typename
        json
      }
      ... on RTCConfigurationNotFoundError {
        __typename
        message
      }
    }
  }
`;

/**
 * __useGetRtcConfigurationQuery__
 *
 * To run a query within a React component, call `useGetRtcConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRtcConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRtcConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRtcConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRtcConfigurationQuery,
    GetRtcConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRtcConfigurationQuery,
    GetRtcConfigurationQueryVariables
  >(GetRtcConfigurationDocument, options);
}
export function useGetRtcConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRtcConfigurationQuery,
    GetRtcConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRtcConfigurationQuery,
    GetRtcConfigurationQueryVariables
  >(GetRtcConfigurationDocument, options);
}
export type GetRtcConfigurationQueryHookResult = ReturnType<
  typeof useGetRtcConfigurationQuery
>;
export type GetRtcConfigurationLazyQueryHookResult = ReturnType<
  typeof useGetRtcConfigurationLazyQuery
>;
export type GetRtcConfigurationQueryResult = Apollo.QueryResult<
  GetRtcConfigurationQuery,
  GetRtcConfigurationQueryVariables
>;
export const MutationUpdateMonitoringConfigurationDocument = gql`
  mutation mutationUpdateMonitoringConfiguration(
    $updateMonitoringConfigurationInput: UpdateMonitoringConfigurationInput!
  ) {
    updateMonitoringConfiguration(
      updateMonitoringConfigurationInput: $updateMonitoringConfigurationInput
    ) {
      ... on MonitoringConfiguration {
        rxChannels {
          danteName
          userName
          selected
        }
        txChannels {
          danteName
          userName
          selected
        }
        id
        useRxDanteChannelNames
        useTxDanteChannelNames
        remoteConfiguration {
          dnsNameOrIpAddress
          password
        }
      }
      ... on MonitoringConfigurationNotFoundError {
        message
      }
    }
  }
`;
export type MutationUpdateMonitoringConfigurationMutationFn =
  Apollo.MutationFunction<
    MutationUpdateMonitoringConfigurationMutation,
    MutationUpdateMonitoringConfigurationMutationVariables
  >;

/**
 * __useMutationUpdateMonitoringConfigurationMutation__
 *
 * To run a mutation, you first call `useMutationUpdateMonitoringConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationUpdateMonitoringConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationUpdateMonitoringConfigurationMutation, { data, loading, error }] = useMutationUpdateMonitoringConfigurationMutation({
 *   variables: {
 *      updateMonitoringConfigurationInput: // value for 'updateMonitoringConfigurationInput'
 *   },
 * });
 */
export function useMutationUpdateMonitoringConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MutationUpdateMonitoringConfigurationMutation,
    MutationUpdateMonitoringConfigurationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MutationUpdateMonitoringConfigurationMutation,
    MutationUpdateMonitoringConfigurationMutationVariables
  >(MutationUpdateMonitoringConfigurationDocument, options);
}
export type MutationUpdateMonitoringConfigurationMutationHookResult =
  ReturnType<typeof useMutationUpdateMonitoringConfigurationMutation>;
export type MutationUpdateMonitoringConfigurationMutationResult =
  Apollo.MutationResult<MutationUpdateMonitoringConfigurationMutation>;
export type MutationUpdateMonitoringConfigurationMutationOptions =
  Apollo.BaseMutationOptions<
    MutationUpdateMonitoringConfigurationMutation,
    MutationUpdateMonitoringConfigurationMutationVariables
  >;
export const UserLoginDocument = gql`
  mutation UserLogin($userLoginInput: UserLoginInput!) {
    userLogin(userLoginInput: $userLoginInput) {
      ... on UserLoginResult {
        ok
        token
      }
      ... on UserLoginError {
        message
        ok
      }
    }
  }
`;
export type UserLoginMutationFn = Apollo.MutationFunction<
  UserLoginMutation,
  UserLoginMutationVariables
>;

/**
 * __useUserLoginMutation__
 *
 * To run a mutation, you first call `useUserLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLoginMutation, { data, loading, error }] = useUserLoginMutation({
 *   variables: {
 *      userLoginInput: // value for 'userLoginInput'
 *   },
 * });
 */
export function useUserLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserLoginMutation,
    UserLoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserLoginMutation, UserLoginMutationVariables>(
    UserLoginDocument,
    options
  );
}
export type UserLoginMutationHookResult = ReturnType<
  typeof useUserLoginMutation
>;
export type UserLoginMutationResult = Apollo.MutationResult<UserLoginMutation>;
export type UserLoginMutationOptions = Apollo.BaseMutationOptions<
  UserLoginMutation,
  UserLoginMutationVariables
>;
